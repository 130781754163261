import roomstayBlogAllArticles, { Props as RoomstayBlogAllArticlesProps } from '@/components/roomstay-blog-all-articles/roomstay-blog-all-articles';
import { BuilderConfig } from '@/types/builder';

export const RoomstayBlogAllArticlesConfig = {
    name: 'All Articles',
    inputs: [
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'offset',
            type: 'number',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogAllArticlesProps> = {
    component: roomstayBlogAllArticles,
    config: RoomstayBlogAllArticlesConfig,
};

export default builderConfig;
