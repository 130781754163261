import { FC } from 'react';

import classNames from 'classnames';
import { useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';
import styles from './roomstay-blog-intro-revpar.module.scss';

export enum IMAGE_POSITION {
    Left = 'Left',
    Right = 'Right',
}

export enum TITLE_SIZE {
    Medium = 'Medium',
    Large = 'Large',
}

export interface RoomstayBlogIntroRevParProps {
    image?: string;
    title: string;
    description: string;
    hideImage?: boolean;
    titleCenter?: boolean;
    position?: IMAGE_POSITION;
    titleSize?: TITLE_SIZE;
    imageMobile?: string;
}

const RoomstayBlogIntroRevPar: FC<RoomstayBlogIntroRevParProps> = (props) => {
    const { image, title, description, position, titleCenter, hideImage, titleSize, imageMobile } = props;
    const { width: windowWidth } = useWindowSize();

    const isMobile = windowWidth <= 992;

    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.wrapper, {
                    [styles.left]: position === IMAGE_POSITION.Left,
                    [styles.right]: position === IMAGE_POSITION.Right,
                })}
            >
                <div className={styles.wrapperText}>
                    <div className={styles.wrapperBox}>
                        <div
                            className={classNames(styles.title, {
                                [styles.titleCenter]: titleCenter,
                                [styles.titleMedium]: titleSize === TITLE_SIZE.Medium,
                                [styles.titleLarge]: titleSize === TITLE_SIZE.Large,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        ></div>

                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        ></div>
                    </div>
                </div>

                <div
                    className={classNames(styles.wrapperImg, {
                        [styles.hideImage]: hideImage,
                    })}
                >
                    {!isMobile ? (
                        <div className={styles.positionImg}>
                            {image && (
                                <LazyLoadImage
                                    className={classNames(styles.img, {
                                        [styles.left]: position === IMAGE_POSITION.Left,
                                        [styles.right]: position === IMAGE_POSITION.Right,
                                    })}
                                    imageUrl={image}
                                    alt={title}
                                    objectFit="cover"
                                />
                            )}
                        </div>
                    ) : (
                        <>
                            {imageMobile && (
                                <LazyLoadImage
                                    className={classNames(styles.img, {
                                        [styles.left]: position === IMAGE_POSITION.Left,
                                        [styles.right]: position === IMAGE_POSITION.Right,
                                    })}
                                    imageUrl={imageMobile}
                                    alt={title}
                                    objectFit="contain"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogIntroRevPar;
