import classNames from 'classnames';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonBookADemo from '../ButtonBookADemo';
import { InputGroup } from '../Inputs/InputGroup';

import styles from './roomstay-blog-sem-helpdesk-form.module.scss';

export interface RoomstayBlogSemHelpdeskFormProps {
    titleForm?: string;
    description?: string;
    successMessage?: string;
    buttonText?: string;
    showMessage?: boolean;
    iconFillColor?: string;
    padding?: 'default' | 'none';
    fontSizeTitle?: 'default' | 'small';
    className?: string;
}

const RoomstaySemHelpdeskForm: FC<RoomstayBlogSemHelpdeskFormProps> = (props) => {
    const { titleForm, description, padding, fontSizeTitle, successMessage, buttonText, showMessage, className = 'helpdesk-form' } = props;

    const formMethods = useForm({
        mode: 'onChange',
    });

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = async () => {
        setSending(true);

        const values = formMethods.getValues();

        try {
            const response = await fetch('/api/book-demo', {
                method: 'POST',
                body: JSON.stringify({
                    email: values.email,
                    message: values.message || ' ',
                    forename: values.forename,
                    surname: values.surname,
                    companyName: values.companyName,
                    number: values.number,
                    formType: 'PPC',
                }),
            });

            formMethods.reset();
            setSuccess(response.ok);
            setSending(false);
        } catch (ex) {
            setSending(false);
        }
    };

    return (
        <div className={classNames(styles.centeredBody, styles[`centeredBody-padding-${padding}`])}>
            <div className={styles.container}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <div className={(classNames(styles.title), styles[`title-font-${fontSizeTitle}`])}>{titleForm}</div>
                    <div className={styles.helpdeskInputs}>
                        <InputGroup dimensions="2/2" className={className}>
                            <div className={styles.margBottom}>
                                <input type="text" required className="text-input" placeholder="First name" tabIndex={1} {...formMethods.register('forename')} />
                            </div>
                            <div className={styles.margBottom}>
                                <input type="text" required className="text-input" placeholder="Last name" tabIndex={1} {...formMethods.register('surname')} />
                            </div>
                        </InputGroup>
                        <div className={styles.margBottom}>
                            <input type="email" required className="text-input" placeholder="Your Email" tabIndex={1} {...formMethods.register('email')} />
                        </div>
                        <div className={styles.margBottom}>
                            <input type="phone" required className="text-input" placeholder="Your phone number" tabIndex={1} {...formMethods.register('number')} />
                        </div>

                        {showMessage && (
                            <div className={styles.margBottom}>
                                <textarea className="text-input" placeholder="Your message" tabIndex={1} {...formMethods.register('message')} />
                            </div>
                        )}

                        {!showMessage && (
                            <div className={styles.margBottom}>
                                <input type="companyName" required className="text-input" placeholder="Your company name" tabIndex={1} {...formMethods.register('companyName')} />
                            </div>
                        )}
                    </div>

                    <div className="align-items-center">
                        <div className="body-text --small --cyan u-pad-right" style={{ opacity: success ? 1 : 0, marginBottom: 20 }}>
                            <strong>{successMessage || 'Thanks! Message sent.'}</strong>
                        </div>

                        <ButtonBookADemo
                            className={styles.button}
                            type="secondary"
                            loading={sending}
                            htmlType="submit"
                            theme="black"
                            color="white"
                            iconFillColor
                            onSubmit={async () => {
                                await formMethods.handleSubmit(onSubmit)();
                            }}
                            style={{ flexShrink: 1, display: 'flex', whiteSpace: 'nowrap', marginBottom: '0' }}
                        >
                            {buttonText}
                        </ButtonBookADemo>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RoomstaySemHelpdeskForm;
