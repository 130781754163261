import { BuilderConfig } from '@/types/builder';

import RoomstayBlogTextFooter, { RoomstayBlogTextFooterProps } from './roomstay-blog-text-footer';

export const RoomStayBlogTextFooterBuilderConfig = {
    name: 'Text Footer',
    inputs: [
        {
            name: 'headText',
            type: 'html',
            defaultValue: '',
        },

        {
            name: 'subText',
            type: 'html',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogTextFooterProps> = {
    component: RoomstayBlogTextFooter,
    config: RoomStayBlogTextFooterBuilderConfig,
};

export default builderConfig;
