import { FC } from 'react';

import { LazyLoadImage } from '../lazy-load-image';
import styles from './roomstay-blog-sem-list-solution.module.scss';

export interface SemListSolutionProps {
    items: {
        icon: string;
        title: string;
        description: string;
    }[];
}

const RoomstayBlogSemListSolution: FC<SemListSolutionProps> = (props) => {
    const { items = [] } = props;

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.content}>
                    {items.map((item, index) => {
                        return (
                            <div className={styles.item} key={new Date().getTime() + index}>
                                {item.icon && <LazyLoadImage imageUrl={item.icon} className={styles.icon} alt="avatar" loadingPlaceholder={false} />}
                                <div
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: item.title,
                                    }}
                                ></div>
                                <div
                                    className={styles.description}
                                    dangerouslySetInnerHTML={{
                                        __html: item.description,
                                    }}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogSemListSolution;
