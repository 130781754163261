import RoomstayBlogTextWrap, { RoomstayBlogTextWrapProps } from '@/components/roomstay-blog-text-wrap/roomstay-blog-text-wrap';
import { BuilderConfig } from '@/types/builder';

export const config = {
    name: 'Text Wrap',
    inputs: [
        {
            name: 'text',
            type: 'richText',
        },
        {
            name: 'dark',
            type: 'boolean',
        },
        {
            name: 'noPadding',
            type: 'boolean',
        },
        {
            name: 'positionText',
            type: 'string',
            enum: ['left', 'center', 'right'],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogTextWrapProps> = {
    component: RoomstayBlogTextWrap,
    config,
};

export default builderConfig;
