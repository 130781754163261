import { BuilderConfig } from '@/types/builder';

import RoomstayMembers, { Props } from './roomstay-members';

export const RoomstayMemberBuilderConfig = {
  name: 'Roomstay members',
  inputs: [
    {
      name: 'title',
      type: 'text',
    },
    {
      name: 'subTitle',
      type: 'text',
    },
    {
      name: 'description',
      type: 'text',
    },
    {
      name: 'members',
      type: 'list',
      subFields: [
        {
          name: 'avatar',
          type: 'file',
        },
        {
          name: 'name',
          type: 'text',
        },
        {
          name: 'jobTitle',
          type: 'text',
        },
        {
          name: 'description',
          type: 'richText',
          defaultValue: '',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: RoomstayMembers,
  config: RoomstayMemberBuilderConfig,
};

export default builderConfig;
