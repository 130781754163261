// import type { Component } from '@builder.io/sdk';
import dynamic from 'next/dynamic';

import { BuilderProps } from '@/components/roomstay-blog-featured-articles/roomstay-blog-featured-articles';
import { BuilderConfig } from '@/types/builder';

const RoomstayBlogFeaturedArticles = dynamic(() => import('@/components/roomstay-blog-featured-articles/roomstay-blog-featured-articles'));

export const RoomstayBlogFeaturedArticlesConfig = {
    name: 'Featured Articles',
    inputs: [
        {
            name: 'backgroundColor',
            type: 'color',
        },
        {
            name: 'articles',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'article',
                    type: 'reference',
                    model: 'article',
                },
            ],
        },
        {
            hideFromUI: true,
            name: 'fixedArticles',
            type: 'list',
        },
    ],
};

const builderConfig: BuilderConfig<BuilderProps> = {
    component: RoomstayBlogFeaturedArticles,
    config: RoomstayBlogFeaturedArticlesConfig,
};

export default builderConfig;
