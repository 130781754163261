import React, { FC } from 'react';
import FadeUpOnVisible from '@/components/animations/FadeUpOnVisible';
import CentralYoutubeBlock from '@/components/CentralYoutubeBlock';

export interface RoomstayBlogHomeYoutubeProps {
    url?: string;
    placeholder?: string;
}

const RoomstayBlogHomeYoutube: FC<RoomstayBlogHomeYoutubeProps> = ({ url, placeholder }) => {
    if (!url) return null;
    return (
        <div className="container --padded">
            <FadeUpOnVisible>
                <CentralYoutubeBlock url={url} placeholderImageSrc={placeholder || ''} />
            </FadeUpOnVisible>
        </div>
    );
};

export default RoomstayBlogHomeYoutube;
