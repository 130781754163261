import { BuilderConfig } from '@/types/builder';

import RoomstayBlogRevPar, { RoomstayBlogIntroRevParProps } from './roomstay-blog-intro-revpar';

export const RoomstayBlogIntroRevParBuilderConfig = {
    name: 'Intro RevPar',
    inputs: [
        {
            name: 'image',
            type: 'file',
        },
        {
            name: 'imageMobile',
            type: 'file',
        },
        {
            name: 'position',
            type: 'text',
            enum: ['Left', 'Right'],
        },
        {
            name: 'hideImage',
            type: 'boolean',
        },

        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'titleCenter',
            type: 'boolean',
        },
        {
            name: 'titleSize',
            type: 'text',
            enum: ['Medium', 'Large'],
        },
        {
            name: 'description',
            type: 'html',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogIntroRevParProps> = {
    component: RoomstayBlogRevPar,
    config: RoomstayBlogIntroRevParBuilderConfig,
};

export default builderConfig;
