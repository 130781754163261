import classNames from 'classnames';
import * as React from 'react';

export interface InputGroupProps {
    children: React.ReactElement | React.ReactElement[];
    className?: string;

    dimensions?: '1' | '2/2' | '3/1' | '1/3';
}

export function InputGroup(props: InputGroupProps) {
    const classes = classNames('be-form-group', {
        [`s-${props.dimensions}`]: !!props.dimensions,
    });

    const classNameProps = classNames('helpdesk-form', {
        [`s-${props.dimensions}`]: !!props.dimensions,
    });

    let innerLayout;

    const childrenArray: any[] = React.Children.toArray(props.children);
    switch (props.dimensions) {
        case '1/3':
        case '3/1':
        case '2/2':
            innerLayout = (
                <>
                    <div>{childrenArray[0]}</div>
                    <div className={childrenArray[1].type.name === InputGroup.name ? 'no-marg' : ''}>{childrenArray[1]}</div>
                </>
            );

            break;
        case '1':
        default:
            innerLayout = props.children;
            break;
    }

    return props.className ? <div className={classNameProps}>{innerLayout}</div> : <div className={classes}>{innerLayout}</div>;
}
