import { FC, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useForm } from 'react-hook-form';
import { InputGroup } from '../Inputs/InputGroup';
import Button from '../button';

export interface RoomstayBlogHelpdeskFormProps {
    title?: string;
    description?: string;
    successMessage?: string;
    textColorInput?: string;
}

const RoomstayBlogHelpdeskForm: FC<RoomstayBlogHelpdeskFormProps> = ({ title, description, successMessage, textColorInput }) => {
    const formMethods = useForm({
        mode: 'onChange',
    });

    const [type, setType] = useState('');
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const dropdownOptions = ['Talk to Sales', 'Customer Support', 'Media Enquiry'].map((item) => ({
        label: item,
        value: item,
    }));

    const onSubmit = async () => {
        setSending(true);

        const values = formMethods.getValues();

        try {
            const response = await fetch('/api/ticket/raise', {
                method: 'POST',
                body: JSON.stringify({
                    type: type,
                    email: values.email,
                    message: values.message,
                    name: values.name,
                    mobile: values.mobile,
                }),
            });

            setType('');
            formMethods.reset();
            setSuccess(response.ok);
            setSending(false);
        } catch (ex) {
            setSending(false);
        }
    };
    return (
        <div className="centered-body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <div className="u-marg-bottom">
                                <h1 className="headline u-text-center">{title}</h1>
                            </div>
                            <div className="u-marg-bottom--largest">
                                <p className="body-text u-text-center">{description}</p>
                            </div>
                            <div className="helpdesk-inputs">
                                <InputGroup dimensions="2/2">
                                    <div className="u-marg-bottom">
                                        <input
                                            type="text"
                                            required
                                            style={{ color: textColorInput }}
                                            className="text-input"
                                            placeholder="Your name"
                                            tabIndex={1}
                                            {...formMethods.register('name')}
                                        />
                                    </div>
                                    <div className="u-marg-bottom">
                                        <input
                                            type="text"
                                            style={{ color: textColorInput }}
                                            required
                                            className="text-input"
                                            placeholder="Your number"
                                            tabIndex={1}
                                            {...formMethods.register('mobile')}
                                        />
                                    </div>
                                </InputGroup>
                                <div className="u-marg-bottom">
                                    <input
                                        style={{ color: textColorInput }}
                                        type="email"
                                        required
                                        className="text-input"
                                        placeholder="Your Email"
                                        tabIndex={1}
                                        {...formMethods.register('email')}
                                    />
                                </div>
                                <div className="u-marg-bottom" style={{ ['--colorText' as string]: textColorInput }} tabIndex={2}>
                                    <Dropdown
                                        className="color-text"
                                        options={dropdownOptions}
                                        placeholder="Type of enquiry"
                                        arrowOpen="⌃"
                                        arrowClosed="⌄"
                                        value={type}
                                        onChange={(value) => setType(value.value)}
                                    />
                                </div>
                                <div className="u-marg-bottom">
                                    <textarea
                                        style={{ color: textColorInput }}
                                        className="text-input"
                                        placeholder="Type your message, questions, details here..."
                                        tabIndex={3}
                                        {...formMethods.register('message')}
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="body-text --small --cyan u-pad-right" style={{ opacity: success ? 1 : 0 }}>
                                    <strong>{successMessage || 'Thanks! Message sent.'}</strong>
                                </div>
                                <Button
                                    type="secondary"
                                    loading={sending}
                                    htmlType="submit"
                                    theme="primary"
                                    onSubmit={async () => {
                                        await formMethods.handleSubmit(onSubmit)();
                                    }}
                                    style={{ flexShrink: 1, display: 'flex', whiteSpace: 'nowrap' }}
                                >
                                    Send Message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogHelpdeskForm;
