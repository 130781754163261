import { BuilderConfig } from '@/types/builder';

import BlogHomeHero, { Props } from './roomstay-blog-blog-hero';

export const RoomstayBlogHomeHeroBuilderConfig = {
    name: 'Blog Home Hero',
    inputs: [],
};

const builderConfig: BuilderConfig<Props> = {
    component: BlogHomeHero,
    config: RoomstayBlogHomeHeroBuilderConfig,
};

export default builderConfig;
