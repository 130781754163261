import classNames from 'classnames';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import RoomstayBlogProductsCard from '../roomstay-blog-products-card/roomstay-blog-product-card';
import ListButton from './list-button/list-button';
import styles from './roomstay-blog-products-hero.module.scss';
import TextIntro, { TexIntroProps } from './text-intro/text-intro';

interface ProductsCardProps {
    inverted?: boolean;
    description: string;
    graphic?: string;
    title?: string;
    ctaButton?: {
        text?: string;
        href?: string;
    };
}

interface ButtonProps {
    name?: string;
    sectionId?: string;
    backgroundColor?: string;
}

export interface RoomstayBlogProductsHeroProps extends TexIntroProps {
    card: ProductsCardProps;
    buttons?: ButtonProps[];
}

const RoomstayBlogProductsHero: FC<RoomstayBlogProductsHeroProps> = (props) => {
    const { title, description, card, buttons } = props;
    const { width: windowWidth } = useWindowSize();

    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 991;

    return (
        <div className={styles.container}>
            <div className={styles.background}>
                <ClippedBox
                    configs={[
                        {
                            rad: isMobile ? Math.tan(101 / 375) : Math.tan(511 / 1920),
                            position: POSITION.BottomRight,
                        },
                    ]}
                    className={classNames(styles.background, styles.backgroundClippedBottom)}
                    style={{
                        backgroundColor: '#fff',
                        maxHeight: isMobile ? 101 : isTablet ? 300 : 500,
                        transform: 'rotate(180deg)',
                        marginBottom: -1,
                    }}
                />
            </div>

            <div className={styles.text}></div>

            <TextIntro title={title} description={description} />

            <div className={styles.listButton}>
                {buttons?.map((button, index) => {
                    return (
                        <div key={new Date().getTime() + index}>
                            <ListButton buttonName={button.name} sectionId={button.sectionId} />
                        </div>
                    );
                })}
            </div>

            <RoomstayBlogProductsCard title={card?.title} description={card?.description} graphic={card?.graphic} inverted ctaButton={card.ctaButton} />
        </div>
    );
};

export default RoomstayBlogProductsHero;
