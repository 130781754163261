import { BuilderConfig } from '@/types/builder';
import ButtonBookADemo, { BookADemoProps } from './index';

export const RoomstayBlogBookADemoConfig = {
    name: 'Button Custom',
    inputs: [
        {
            name: 'headline',
            type: 'html',
        },
        {
            name: 'subHeadline',
            type: 'html',
        },
        {
            name: 'buttonName',
            type: 'string',
        },
        {
            name: 'buttonLink',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<BookADemoProps> = {
    component: ButtonBookADemo,
    config: RoomstayBlogBookADemoConfig,
};

export default builderConfig;
