import { BuilderConfig } from '@/types/builder';

import RoomstayBlogFeaturedCard, { RoomstayBlogFeaturedCardProps } from './roomstay-blog-featured-card';

import { withChildren } from '@builder.io/react';

export const RoomstayBlogFeaturedCardBuilderConfig = {
    name: 'Featured Card',
    inputs: [
        {
            name: 'graphic',
            type: 'file',
        },
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'description',
            type: 'html',
        },
        {
            name: 'inverted',
            type: 'boolean',
        },
        {
            name: 'fontSize',
            type: 'text',
            enum: ['default', 'medium'],
        },
        {
            name: 'dividerColor',
            type: 'color',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogFeaturedCardProps> = {
    component: withChildren(RoomstayBlogFeaturedCard),

    config: RoomstayBlogFeaturedCardBuilderConfig,
};

export default builderConfig;
