import { BuilderConfig } from '@/types/builder';

import RoomstayBlogRevPar, { RoomstayBlogRevParProps } from './roomstay-blog-revpar';

export const RoomstayBlogProductThemeBuilderConfig = {
    name: 'RevPar',
    inputs: [
        {
            name: 'spaceDefault',
            type: 'boolean',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogRevParProps> = {
    component: RoomstayBlogRevPar,
    config: RoomstayBlogProductThemeBuilderConfig,
};

export default builderConfig;
