import { BuilderConfig } from '@/types/builder';

import RoomstayBlogSummingUp, { Props } from './roomstay-blog-summing-up';

export const RoomstayBlogSummingUpBuilderConfig = {
    name: 'Article Summing Up',
    inputs: [
        {
            name: 'items',
            type: 'list',
            defaultValue: '',
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                    defaultValue: '',
                },
                {
                    name: 'description',
                    type: 'string',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogSummingUp,
    config: RoomstayBlogSummingUpBuilderConfig,
};

export default builderConfig;
