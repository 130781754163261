import React, { CSSProperties, FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

export enum POSITION {
    TopLeft = 0,
    TopRight = 1,
    BottomRight = 2,
    BottomLeft = 3,
}

const ClippedBox: FC<
    PropsWithChildren<JSX.IntrinsicElements['div']> & {
        configs: { position: POSITION; rad: number }[];
    }
> = ({ style: customStyle, configs, ...props }) => {
    const [style, setStyle] = useState<CSSProperties>({});
    const [ref, { width, height }] = useElementSize<HTMLDivElement>();
    const { width: windowWidth } = useWindowSize();

    const createPolygon = (): string => {
        const edges = [
            ['0%', '0%'],
            ['0%', '100%'],
            ['100%', '100%'],
            ['100%', '0%'],
        ];
        configs.forEach(({ rad, position }) => {
            const index = edges.findIndex((v, index) => index === position);
            const offset = Math.tan(rad) * width;
            switch (position) {
                case POSITION.TopLeft:
                    edges.splice(index, 1, ['0%', `${offset}px`]);
                    break;
                case POSITION.TopRight:
                    edges.splice(index, 1, ['100%', `${offset}px`]);
                    break;
                case POSITION.BottomLeft:
                    edges.splice(index, 1, ['0%', `${Math.max(height - offset, 0)}px`]);
                    break;
                case POSITION.BottomRight:
                    edges.splice(index, 1, ['100%', `${Math.max(height - offset, 0)}px`]);
                    break;
            }
        });
        return edges.map((arr) => arr.join(' ')).join(',');
    };

    useEffect(() => {
        const polygon = createPolygon();
        setStyle({
            clipPath: `polygon(${polygon})`,
        });
    }, [width, height, windowWidth, customStyle]);

    return (
        <div
            ref={ref}
            style={{
                marginTop: -1,
                ...(customStyle || {}),
                ...style,
            }}
            {...props}
        />
    );
};

export default ClippedBox;
