import { FC } from 'react';
import AboutHeroImageGrid, { ImageGridProps } from './image-grid/image-grid';
import TextIntro, { TexIntroProps } from './text-intro/text-intro';

import classNames from 'classnames';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import styles from './roomstay-blog-about-hero.module.scss';

export interface RoomstayBlogAboutHeroProps extends TexIntroProps, ImageGridProps {}

const RoomstayBlogAboutHero: FC<RoomstayBlogAboutHeroProps> = (props) => {
    const { title, description, images } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[]}
                className={styles.background}
                style={{
                    backgroundColor: '#ff0068',
                    maxHeight: `${height / 2}px`,
                }}
            />
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(515 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.backgroundClipped)}
                style={{
                    backgroundColor: '#ff0068',
                    maxHeight: `${windowWidth > 768 ? introHeight + 130 + 100 : introHeight + 77 + 60}px`,
                    marginTop: '-2px',
                }}
            />
            <TextIntro ref={introRef} title={title} description={description} />
            <AboutHeroImageGrid images={images} />
        </div>
    );
};

export default RoomstayBlogAboutHero;
