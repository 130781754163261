import { FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import ButtonBookADemo from '../ButtonBookADemo';
import { InputGroup } from '../Inputs/InputGroup';

import styles from './roomstay-blog-card-pricing.module.scss';

export interface BlogCardPricing {
    contentText?: {
        subTitle?: string;
        title?: string;
        description?: string;
    };

    contentForm?: {
        successMessage?: string;
        buttonText?: string;
        showMessage?: boolean;
        iconFillColor?: string;
        padding?: 'default' | 'none';
        fontSizeTitle?: 'default' | 'small';
        className?: string;
        backgroundColor?: string;
        backgroundButton?: string;
    };
}

const RoomStayBlogCardPricing: FC<BlogCardPricing> = (props) => {
    const { contentText, contentForm } = props;

    const formMethods = useForm({
        mode: 'onChange',
    });

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = async () => {
        setSending(true);

        const values = formMethods.getValues();

        try {
            const response = await fetch('/api/book-demo', {
                method: 'POST',
                body: JSON.stringify({
                    email: values.email,
                    message: values.message || ' ',
                    forename: values.forename,
                    surname: values.surname,
                    companyName: values.companyName,
                    number: values.number,
                    formType: 'PPC',
                }),
            });

            formMethods.reset();
            setSuccess(response.ok);
            setSending(false);
        } catch (ex) {
            setSending(false);
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.wrapperContent}>
                <div className={styles.gridContent}>
                    <div className={styles.contentText}>
                        {contentText?.subTitle && <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: contentText?.subTitle }}></div>}
                        {contentText?.title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: contentText?.title }}></div>}
                        {contentText?.description && (
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: contentText?.description || '',
                                }}
                            ></div>
                        )}
                    </div>

                    <div className={styles.contentForm}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <div className={styles.helpdeskInputs}>
                                <InputGroup dimensions="2/2" className="be-form-group">
                                    <div className={styles.margBottom}>
                                        <input type="text" required className="text-input" placeholder="First name" tabIndex={1} {...formMethods.register('forename')} />
                                    </div>

                                    <div className={styles.margBottom}>
                                        <input type="text" required className="text-input" placeholder="Last name" tabIndex={1} {...formMethods.register('surname')} />
                                    </div>
                                </InputGroup>

                                <div className={styles.margBottom}>
                                    <input type="email" required className="text-input" placeholder="Your email" tabIndex={1} {...formMethods.register('email')} />
                                </div>

                                <div className={styles.margBottom}>
                                    <input type="phone" required className="text-input" placeholder="Your phone number" tabIndex={1} {...formMethods.register('number')} />
                                </div>

                                {contentForm?.showMessage && (
                                    <div className={styles.margBottom}>
                                        <textarea className="text-input" placeholder="Your message" tabIndex={1} {...formMethods.register('message')} />
                                    </div>
                                )}

                                {!contentForm?.showMessage && (
                                    <div className={styles.margBottom}>
                                        <input
                                            type="companyName"
                                            required
                                            className="text-input"
                                            placeholder="Number of rooms"
                                            tabIndex={1}
                                            {...formMethods.register('companyName')}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="align-items-center">
                                {contentForm?.successMessage && (
                                    <div className="body-text --small --cyan u-pad-right" style={{ display: success ? 'block' : 'none', marginBottom: 20 }}>
                                        <strong>{contentForm.successMessage || 'Thanks! Message sent.'}</strong>
                                    </div>
                                )}

                                <ButtonBookADemo
                                    className={styles.button}
                                    type="secondary"
                                    loading={sending}
                                    htmlType="submit"
                                    theme="purple"
                                    color="white"
                                    iconFillColor
                                    size="small"
                                    onSubmit={async () => {
                                        await formMethods.handleSubmit(onSubmit)();
                                    }}
                                    style={{ flexShrink: 1, display: 'flex', whiteSpace: 'nowrap', marginBottom: '0' }}
                                >
                                    Get a personalised quote
                                </ButtonBookADemo>

                                <div className={styles.description}>No hidden fees, no surprises. Just a simple step towards bigger earnings and happier guests!</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogCardPricing;
