import { BuilderConfig } from '@/types/builder';

import RoomstayStats, { Props } from './roomstay-stats';

export const RoomstayStatsBuilderConfig = {
  name: 'Roomstay stats',
  inputs: [
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'description',
          type: 'string',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: RoomstayStats,
  config: RoomstayStatsBuilderConfig,
};

export default builderConfig;
