import React, { FC, memo, useContext, useEffect } from 'react';

import ArticleCard, { ArticleCardConfig } from '@/components/article-card/article-card';
import ArticleGridSkeleton from '@/components/skeleton/article-grid-skeleton/article-grid-skeleton';
import { Size, useWindowSize } from '@/hooks/useWindowSize';
import { CategoryCtx } from '@/shared/sharedContext';
import { Article } from '@/types/article';

import styles from './article-grid.module.scss';
import Button from '@/components/button';
import Spinner from '@/components/spinner/spinner';

interface ArticleGridProps {
    articles: Article[];
    setOffsetArticle: (count: number) => void;
    offsetArticle: number;
    isLoadingMoreArticles: boolean;
    templates?: ArticleCardConfig[][];
    itemsPerPage?: number;
    initialOffsetArticle: number;
    onLoadMoreClick?: (currentOffset: number, nextOffset: number) => void;
}

export const defaultTemplates: ArticleCardConfig[][] = [
    [{ large: false }, { large: true }],
    [{ large: false }, { large: false }, { large: false }],
    [{ large: true, dark: true, imageFullBleed: true }, { large: false }],
    [{ large: false }, { large: false }, { large: false }],
];

export const tabletTemplate: ArticleCardConfig[][] = [
    [{ large: false }, { large: false }],
    // [{ large: true }],
];

const ArticleGrid: FC<ArticleGridProps> = (props) => {
    const { articles, setOffsetArticle, offsetArticle, isLoadingMoreArticles, templates, itemsPerPage = 6, initialOffsetArticle, onLoadMoreClick } = props;
    const windowSize: Size = useWindowSize();
    const categoryCtx = useContext(CategoryCtx);

    useEffect(() => {
        if (!categoryCtx) return;
        return () => {
            if (categoryCtx?.setCategoriesToFilter) {
                categoryCtx.setCategoriesToFilter([]);
            }
        };
    }, []);

    const cardTemplate = (windowSize.width || 0) > 1200 ? (templates ? templates : defaultTemplates) : tabletTemplate;

    const handleCards = () => {
        const maxTotalRows = articles.length;
        const cards = [];
        let articleIndex = 0;

        for (let rowNumber = 0; rowNumber < maxTotalRows; rowNumber += 1) {
            const templateIndex = rowNumber % cardTemplate.length;
            const templateRow = cardTemplate[templateIndex];
            if (articleIndex >= maxTotalRows) {
                break;
            }
            for (let colNumber = 0; colNumber < templateRow.length; colNumber++) {
                const cardType = templateRow[colNumber];
                if (articleIndex >= maxTotalRows) {
                    break;
                }
                const article = articles[articleIndex];
                if (!cardType.large) {
                    cards.push(<ArticleCard key={Math.random()} {...cardType} large={false} article={article} />);
                }
                if (cardType.large) {
                    cards.push(
                        <div className={styles.articleLarge} key={Math.random()}>
                            <ArticleCard {...cardType} large={true} article={article} />
                        </div>
                    );
                }

                articleIndex += 1;
            }
        }

        return cards;
    };

    const handleLoadMoreArticle = () => {
        const nextOffset = offsetArticle + itemsPerPage;
        setOffsetArticle(nextOffset);
        onLoadMoreClick?.(offsetArticle, nextOffset);
    };

    const renderButtonLoadMore = () => {
        if (offsetArticle - initialOffsetArticle > articles.length) {
            return null;
        }

        return (
            <Button onClick={() => handleLoadMoreArticle()} loading={isLoadingMoreArticles}>
                MORE POSTS
            </Button>
        );
    };

    const renderArticleContent = () => {
        if (articles.length === 0 && !isLoadingMoreArticles) {
            return null;
        }

        if (articles.length === 0) {
            return <ArticleGridSkeleton length={7} templates={cardTemplate} />;
        }

        return <div className={styles.articlesContainer}>{handleCards().map((card) => card)}</div>;
    };

    return (
        <div className={styles.articleGrid}>
            {renderArticleContent()}
            <div className={styles.loadMoreContainer}>
                {renderButtonLoadMore()}
                <div className={styles.spinnerLoadMoreContainer}>
                    <Spinner size={32} strokeWidth={3} hide={!isLoadingMoreArticles} />
                </div>
            </div>
        </div>
    );
};

export default memo(ArticleGrid);
