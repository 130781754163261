import { withChildren } from '@builder.io/react';

import RoomstayBlogImageBlock, { IMAGE_POSITION, RoomstayBlogImageBlockProps } from '@/components/roomstay-blog-image-block/roomstay-blog-image-block';
import { BuilderConfig } from '@/types/builder';

export const config = {
    name: 'Image Block',
    inputs: [
        {
            name: 'image',
            type: 'object',
            subFields: [
                {
                    name: 'source',
                    type: 'file',
                },
                {
                    name: 'overflowValue',
                    type: 'number',
                },
                {
                    name: 'fluid',
                    type: 'boolean',
                },
                {
                    name: 'position',
                    type: 'string',
                    enum: Object.values(IMAGE_POSITION),
                },
            ],
        },
        {
            name: 'text',
            type: 'object',
            subFields: [
                {
                    name: 'paddingTop',
                    type: 'string',
                },
                {
                    name: 'paddingBottom',
                    type: 'string',
                },
                {
                    name: 'dark',
                    type: 'boolean',
                },
                {
                    name: 'text',
                    type: 'richText',
                },
            ],
        },
        {
            name: 'topColor',
            type: 'color',
        },
        {
            name: 'bottomColor',
            type: 'color',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogImageBlockProps> = {
    component: withChildren(RoomstayBlogImageBlock),
    config,
};

export default builderConfig;
