import classNames from 'classnames';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';
import FadeUpOnVisible from '../animations/FadeUpOnVisible';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import styles from './roomstay-blog-product-theme.module.scss';

export interface RoomstayBlogProductThemeProps {
    children: React.ReactNode;
    backgroundColor?: string;
}
const RoomstayBlogProductTheme: FC<RoomstayBlogProductThemeProps> = (props) => {
    const { children } = props;

    const { width: windowWidth } = useWindowSize();

    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 991;

    return (
        <div className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: isMobile ? Math.tan(101 / 375) : Math.tan(511 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.backgroundClippedBottom)}
                style={{
                    backgroundColor: `${props.backgroundColor ?? '#000'}`,
                    maxHeight: isMobile ? 101 : isTablet ? 300 : 500,
                    transform: 'rotate(180deg)',
                    marginBottom: -1,
                }}
            />

            <div className={styles.children}>
                <FadeUpOnVisible>{children}</FadeUpOnVisible>
            </div>
        </div>
    );
};

export default RoomstayBlogProductTheme;
