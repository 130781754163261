import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHeroUseCases, { RoomstayBlogHeroUseCasesProps } from './roomstay-blog-use-case-hero';

export const RoomstayBlogHeroUseCasesBuilderConfig = {
    name: 'Hero Use Cases',
    inputs: [
        {
            name: 'heading',
            type: 'string',
        },
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'description',
            type: 'html',
        },
        {
            name: 'image',
            type: 'file',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHeroUseCasesProps> = {
    component: RoomstayBlogHeroUseCases,
    config: RoomstayBlogHeroUseCasesBuilderConfig,
};

export default builderConfig;
