import { FC } from 'react';

import styles from './roomstay-blog-text-intro-user.module.scss';

export interface Props {
    title: string;
    description: string;
    subTitle: string;
}

const RoomStayBlogTexIntroUser: FC<Props> = (props) => {
    const { title, description, subTitle } = props;

    return (
        <div className={styles.container}>
            <div className={styles.containerWrapper}>
                <div className={styles.containerWrapperTitle}>
                    <div className={styles.containerWrapperTitleText} dangerouslySetInnerHTML={{ __html: title }}></div>
                    <div className={styles.containerWrapperTitleDivider}></div>
                    <div className={styles.containerSubTitle} dangerouslySetInnerHTML={{ __html: subTitle }}></div>
                    <div className={styles.containerDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogTexIntroUser;
