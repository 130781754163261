import FadeUpOnVisible from '@/components/animations/FadeUpOnVisible';
import Card from '@/components/Card';
import AnimationDelay from '@/models/AnimationDelay';
import { FC } from 'react';

export enum EColor {
    White = 'White',
    Dark = 'Dark',
}

export enum SPACING_CONTENT {
    baseSpace = 'base-space',
    small = 'small',
    large = 'large',
    largest = 'largest',
}

const spacingContent = {};

export enum STYLE_TITLE {
    centered = 'centered',
    normal = 'normal',
}

export interface RoomstayBlogIntroductionProps {
    title?: string;
    spacingContent?: SPACING_CONTENT;
    styleTitle?: STYLE_TITLE;

    cards?: {
        title?: string;
        content?: string;
        background?: 'primary' | 'secondary' | 'cyan' | 'navy' | 'white' | 'purple';
        hideIcon?: boolean;
        iconFillColor?: string;
        minHeight?: number;
    }[];
}

const RoomstayBlogHomeIntroduction: FC<RoomstayBlogIntroductionProps> = ({ title, spacingContent, styleTitle, cards: [card1, card2, card3] = [] }) => {
    return (
        <div className="container --padded">
            <FadeUpOnVisible>
                <div className={`d-flex align-items-center flex-column u-marg-bottom--${spacingContent || 'largest'}`}>
                    <h2
                        className={`headline --${styleTitle || 'centered'}`}
                        dangerouslySetInnerHTML={{
                            __html: title || '',
                        }}
                    />
                </div>
            </FadeUpOnVisible>

            <div className="row">
                <div className="col-md-4">
                    <FadeUpOnVisible>
                        <Card
                            title={card1?.title || ''}
                            description={card1?.content || ''}
                            color={card1?.background ?? 'secondary'}
                            showIcon={!card1?.hideIcon ?? true}
                            minheight={card1?.minHeight ? `${card1?.minHeight}` : undefined}
                            icon={
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <path
                                            d="M38.5957 33.7297H34.5187V25.6663C34.5187 24.8962 33.9298 24.3073 33.1597 24.3073C32.3896 24.3073 31.8007 24.8962 31.8007 25.6663V33.7297H27.9049V22.903C27.9049 22.1329 27.316 21.544 26.5459 21.544C25.7758 21.544 25.1869 22.1329 25.1869 22.903V33.7297H21.3364V26.7535C21.3364 25.9834 20.7475 25.3945 19.9774 25.3945C19.2072 25.3945 18.6183 25.9834 18.6183 26.7535V33.7297H14.7678V21.2722C14.7678 20.5021 14.1789 19.9132 13.4088 19.9132C12.6387 19.9132 12.0498 20.5021 12.0498 21.2722V33.7297H8.15402V27.2971C8.15402 26.527 7.56512 25.9381 6.79502 25.9381C6.02492 25.9381 5.43601 26.527 5.43601 27.2971V33.7297H1.359C0.588901 33.7297 0 34.3186 0 35.0887C0 35.8588 0.588901 36.4478 1.359 36.4478H38.641C39.4111 36.4478 40 35.8588 40 35.0887C40 34.3186 39.3658 33.7297 38.5957 33.7297Z"
                                            fill={`${card1?.iconFillColor ?? 'white'}`}
                                        />
                                        <path
                                            d="M5.57191 16.8645C5.88901 16.8645 6.25141 16.7286 6.52321 16.5021L13.4541 9.84294L20.6568 15.5055C21.1552 15.9132 21.8347 15.9132 22.333 15.5055L32.1178 7.84974L31.8007 11.8814C31.7554 12.6515 32.299 13.2857 33.0691 13.331C33.1144 13.331 33.1597 13.331 33.1597 13.331C33.8845 13.331 34.4734 12.7874 34.5187 12.0626L35.0623 4.99584C35.0623 4.95054 35.0623 4.90524 35.0623 4.85994C35.0623 4.54284 34.9717 4.27104 34.7905 3.99924C34.4734 3.59154 33.9751 3.41034 33.4768 3.50094L26.1835 3.00263C25.4134 2.95733 24.7792 3.50093 24.7339 4.27104C24.6886 5.04114 25.2322 5.67534 26.0023 5.72064L30.034 5.99244L21.4723 12.6968L14.1789 6.94374C13.6353 6.53604 12.8652 6.58134 12.4122 7.03434L4.62061 14.5089C4.07701 15.0072 4.07701 15.8679 4.57531 16.4115C4.84711 16.7286 5.20951 16.8645 5.57191 16.8645Z"
                                            fill={`${card1?.iconFillColor ?? 'white'}`}
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="40" height="40" fill={`${card1?.iconFillColor ?? 'white'}`} />
                                        </clipPath>
                                    </defs>
                                </svg>
                            }
                        />
                    </FadeUpOnVisible>
                </div>
                <div className="col-md-4">
                    <FadeUpOnVisible delay={AnimationDelay.A200}>
                        <Card
                            title={card2?.title || ''}
                            description={card2?.content || ''}
                            showIcon={!card2.hideIcon ?? true}
                            minheight={card2?.minHeight ? `${card2?.minHeight}` : undefined}
                            color={card2?.background ?? 'cyan'}
                            icon={
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.6026 12.3294C20.8062 13.1243 20.3582 14.2172 20.3582 15.4095C20.3582 16.6017 20.8062 17.645 21.6026 18.4895C22.4487 19.2843 23.5438 19.7314 24.6887 19.7314C25.7837 19.7314 26.9285 19.334 27.725 18.4895C29.3675 16.8501 29.3675 14.0185 27.725 12.3791C26.0824 10.6901 23.2452 10.6901 21.6026 12.3294ZM25.6344 16.3533C25.1366 16.8501 24.2407 16.8501 23.6931 16.3533C23.4443 16.1049 23.3447 15.7572 23.3447 15.4095C23.3447 15.0617 23.494 14.714 23.7429 14.4656C24.2407 13.9688 25.1864 13.9688 25.6842 14.4656C26.1321 14.9624 26.1321 15.8566 25.6344 16.3533Z"
                                        fill={`${card2?.iconFillColor ?? '#1C1624'}`}
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M38.3271 0.00931459C38.7751 -0.0403633 39.2231 0.108671 39.5715 0.456416C39.9199 0.804161 40.0693 1.25126 39.9697 1.64868L38.6258 12.9256C38.576 13.2733 38.4267 13.5714 38.1778 13.8198L34.0464 17.943L34.594 25.196C34.6437 25.5934 34.4944 25.9908 34.2455 26.2889L26.6797 35.2806C26.381 35.6284 25.9828 35.8271 25.5348 35.8271C25.4851 35.8271 25.4353 35.8146 25.3855 35.8022C25.3357 35.7898 25.286 35.7774 25.2362 35.7774C24.6886 35.6284 24.2407 35.2806 24.0913 34.7341L22.4985 29.4683L21.1546 30.8096C20.8559 31.1077 20.5075 31.2567 20.1093 31.2567C19.8106 31.2567 19.512 31.1573 19.2631 31.0083L16.9237 35.2806C16.7246 35.678 16.2766 35.9761 15.8286 36.0258H15.6295C15.2313 36.0258 14.8829 35.8767 14.5842 35.5787L4.38024 25.3947C4.08159 25.0966 3.93226 24.5999 3.98204 24.1528C4.03181 23.7057 4.33047 23.3082 4.72867 23.0598L9.00936 20.725C8.86004 20.4766 8.76049 20.1785 8.76049 19.8805C8.76049 19.483 8.90981 19.1353 9.20847 18.8372L10.5524 17.4959L5.2762 15.9559C4.72867 15.7572 4.33047 15.3598 4.23092 14.8133C4.13136 14.2669 4.33047 13.7204 4.72867 13.3727L13.738 5.77195C14.0367 5.52356 14.3851 5.4242 14.7833 5.4242L22.1003 5.92098L26.1819 1.8474C26.4308 1.59901 26.7294 1.44997 27.0779 1.4003L38.3271 0.00931459ZM15.1815 8.45455L8.76049 13.8694L12.9416 15.1114L19.3129 8.75262L15.1815 8.45455ZM7.96408 24.7489L15.3309 32.1012L17.1228 28.8722L11.1995 22.9605L7.96408 24.7489ZM26.2317 31.3064L31.6074 24.8979L31.3586 20.7747L24.9873 27.1334L26.2317 31.3064ZM20.1591 27.6799L35.7388 12.1307L36.8339 3.23838L27.9738 4.33129L12.3443 19.9301L20.1591 27.6799Z"
                                        fill={`${card2?.iconFillColor ?? '#1C1624'}`}
                                    />
                                    <path
                                        d="M7.76498 32.2999C7.16767 31.7038 6.22194 31.7038 5.67441 32.2999L1.8417 36.0755C1.24439 36.6716 1.24439 37.6155 1.8417 38.1619C2.14035 38.46 2.53855 38.609 2.88698 38.609C3.28519 38.609 3.63361 38.46 3.93227 38.1619L7.76498 34.3864C8.36229 33.8399 8.36229 32.8961 7.76498 32.2999Z"
                                        fill={`${card2?.iconFillColor ?? '#1C1624'}`}
                                    />
                                    <path
                                        d="M8.81026 35.4296L6.76947 37.4664C6.17216 38.0626 6.17216 39.0064 6.76947 39.5529C7.06812 39.851 7.46632 40 7.81475 40C8.16318 40 8.56139 39.851 8.86004 39.5529L10.9008 37.5161C11.4981 36.92 11.4981 35.9761 10.9008 35.4296C10.3035 34.8832 9.40757 34.8832 8.81026 35.4296Z"
                                        fill={`${card2?.iconFillColor ?? '#1C1624'}`}
                                    />
                                    <path
                                        d="M4.57935 31.2567L2.53855 33.2935C2.2399 33.5916 1.89147 33.7406 1.49327 33.7406C1.14484 33.7406 0.746632 33.5916 0.447979 33.2935C-0.149326 32.747 -0.149326 31.8032 0.447979 31.207L2.48878 29.1702C3.03631 28.5741 3.98204 28.5741 4.57935 29.1702C5.17665 29.7167 5.17665 30.6606 4.57935 31.2567Z"
                                        fill={`${card2?.iconFillColor ?? '#1C1624'}`}
                                    />
                                </svg>
                            }
                        />
                    </FadeUpOnVisible>
                </div>
                <div className="col-md-4">
                    <FadeUpOnVisible delay={AnimationDelay.A400}>
                        <Card
                            title={card3?.title || ''}
                            description={card3?.content || ''}
                            showIcon={!card3?.hideIcon ?? true}
                            minheight={card3?.minHeight ? `${card3?.minHeight}` : undefined}
                            color={card3?.background ?? 'primary'}
                            icon={
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.4485 18.4878V14.3435C24.2559 13.7197 26.3503 11.2243 26.3503 8.23855C26.3503 4.80736 23.5428 2 20.1116 2C16.6804 2 13.873 4.80736 13.873 8.23864C13.873 11.2243 15.9674 13.7197 18.7747 14.3436V17.0618L18.7748 26.3752C18.7746 26.3543 18.7748 26.8654 18.7748 26.3752C19.1759 26.598 21.4485 18.4878 21.4485 18.4878ZM16.5467 8.23864C16.5467 6.2779 18.1509 4.6737 20.1116 4.6737C22.0724 4.6737 23.6766 6.2779 23.6766 8.23864C23.6766 10.1994 22.0724 11.8036 20.1116 11.8036C18.1509 11.8036 16.5467 10.1994 16.5467 8.23864ZM20.1116 27.6675C20.8692 27.6675 21.4484 27.0882 21.4484 26.3307L21.4485 18.4878L18.7747 17.0618L18.7748 26.3752C18.7747 27.0882 19.354 27.6675 20.1116 27.6675Z"
                                        fill={`${card3?.iconFillColor ?? 'white'}`}
                                    />
                                    <path
                                        d="M24 17C23.4477 17 23 17.4477 23 18C23 18.5523 23.4477 19 24 19V17ZM28 20V36H30V20H28ZM27 37H5V39H27V37ZM4 36V20H2V36H4ZM5 19H20V17H5V19ZM24 19H27V17H24V19ZM5 37C4.44772 37 4 36.5523 4 36H2C2 37.6569 3.34315 39 5 39V37ZM28 36C28 36.5523 27.5523 37 27 37V39C28.6569 39 30 37.6569 30 36H28ZM30 20C30 18.3431 28.6569 17 27 17V19C27.5523 19 28 19.4477 28 20H30ZM4 20C4 19.4477 4.44772 19 5 19V17C3.34315 17 2 18.3431 2 20H4Z"
                                        fill={`${card3?.iconFillColor ?? 'white'}`}
                                    />
                                    <path d="M2 23H20" stroke={`${card3?.iconFillColor ?? 'white'}`} strokeWidth="2" />
                                    <path d="M24 23H28" stroke={`${card3?.iconFillColor ?? 'white'}`} strokeWidth="2" strokeLinecap="round" />
                                    <path
                                        d="M11 15V14C11 12.8954 11.8954 12 13 12H17M32 32H35C36.1046 32 37 31.1046 37 30V14C37 12.8954 36.1046 12 35 12H23.5"
                                        stroke={`${card3?.iconFillColor ?? 'white'}`}
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                    <path d="M32 17H37" stroke={`${card3?.iconFillColor ?? 'white'}`} strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            }
                        />
                    </FadeUpOnVisible>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogHomeIntroduction;
