import { FC } from 'react';

import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';

import styles from './article-carousel-item.module.scss';
import Link from 'next/link';

interface Props {
    article: Article;
}

const ArticleCarouselItem: FC<Props> = (props) => {
    const { article } = props;

    return (
        <Link href={article.url || ''} passHref>
            <a className={styles.container}>
                <div className={styles.info}>
                    <span className={styles.category}>{article.categories[0]?.name}</span>
                    &nbsp;&nbsp;&nbsp;<span className={styles.dot}>&#8226;</span>&nbsp;&nbsp;&nbsp;
                    <span className={styles.date}>{article.lastUpdated ? formatDate(article.lastUpdated) : ''}</span>
                </div>
                <div title={article.title} className={styles.title}>
                    {article.title}
                </div>
            </a>
        </Link>
    );
};

export default ArticleCarouselItem;
