import { BuilderConfig } from '@/types/builder';

import RoomstayBlogFadeUp, { RoomstayBlogFadeUpProps } from './roomstay-blog-fade-up';
import { withChildren } from '@builder.io/react';

export const RoomstayBlogFadeUpBuilderConfig = {
    name: 'Fade Up',
    inputs: [
        {
            name: 'autoPadding',
            type: 'boolean',
            defaultValue: true,
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogFadeUpProps> = {
    component: withChildren(RoomstayBlogFadeUp),
    config: RoomstayBlogFadeUpBuilderConfig,
};

export default builderConfig;
