import { BuilderConfig } from '@/types/builder';

import RoomstayBlogSemHero, { BlogSemHeroProps } from './roomstay-blog-sem-hero';

export const RoomstayBlogQuoteBuilderConfig = {
    name: 'Sem Hero',
    inputs: [
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'subTitle',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'html',
            defaultValue: '',
        },
        {
            name: 'image',
            type: 'file',
            defaultValue: '',
        },
        {
            name: 'titleForm',
            type: 'string',
        },
        {
            name: 'showImage',
            type: 'boolean',
        },
        {
            name: 'buttonText',
            type: 'string',
        },
        {
            name: 'hideClipped',
            type: 'boolean',
        },
    ],
};

const builderConfig: BuilderConfig<BlogSemHeroProps> = {
    component: RoomstayBlogSemHero,
    config: RoomstayBlogQuoteBuilderConfig,
};

export default builderConfig;
