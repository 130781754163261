import { BuilderConfig } from '@/types/builder';

import RoomstayBlogProductCardCard, { RoomstayBlogProductCardProps } from './roomstay-blog-product-card';

export const RoomstayBlogProductCardBuilderConfig = {
    name: 'Product Card',
    inputs: [
        {
            name: 'graphic',
            type: 'file',
        },
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'description',
            type: 'html',
        },
        {
            name: 'inverted',
            type: 'boolean',
        },
        {
            name: 'ctaButton',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'href',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogProductCardProps> = {
    component: RoomstayBlogProductCardCard,
    config: RoomstayBlogProductCardBuilderConfig,
};

export default builderConfig;
