import { BuilderConfig } from '@/types/builder';

import RoomStayBlogOurPromise, { Props } from './roomstay-blog-our-promise';

export const RoomstayBlogOurPromiseBuilderConfig = {
    name: 'List Our Promise',
    inputs: [
        {
            name: 'title',
            type: 'html',
        },

        {
            name: 'items',
            type: 'list',
            subFields: [
                {
                    name: 'icon',
                    type: 'file',
                },
                {
                    name: 'text',
                    type: 'text',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomStayBlogOurPromise,
    config: RoomstayBlogOurPromiseBuilderConfig,
};

export default builderConfig;
