import Button from '@/components/button';
import { FC } from 'react';
import styles from './list-button.module.scss';

export interface ListButtonProps {
    onClick?: () => void;
    buttonName?: string;
    sectionId?: string;
    backgroundColor?: string;
}

const ListButton: FC<ListButtonProps> = (props) => {
    const { buttonName, sectionId } = props;

    const handleScrollElementById = () => {
        const element = document.getElementById(sectionId || '');

        if (sectionId === element?.id) {
        }

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    };

    return (
        <Button type="secondary" size="large" htmlType="button" onClick={handleScrollElementById} className={styles.unactive}>
            {buttonName}
        </Button>
    );
};

export default ListButton;
