export const getUrlShare = (type: string, urlShare: string): string => {
    switch (type) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${urlShare}`;
        case 'linkedIn':
            return `https://www.linkedin.com/sharing/share-offsite/?url=${urlShare}`;
        case 'twitter':
            return `https://twitter.com/intent/tweet?url=${urlShare}`;
        default:
            return urlShare;
    }
};

export const getTooltipMessage = (type: string): string => {
    switch (type) {
        case 'facebook':
            return 'Share on Facebook';
        case 'linkedIn':
            return 'Share on LinkedIn';
        case 'twitter':
            return 'Share on Twitter';
        case 'copy':
            return 'Click to copy';
        default:
            return '';
    }
};

interface PopupCenter {
    url: string;
    title: string;
    popupWidth: number;
    popupHeight: number;
}

export const handlePopupCenter = ({ url, title, popupWidth, popupHeight }: PopupCenter) => {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
    const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
        url,
        title,
        `
    scrollbars=yes,
    width=${popupWidth / systemZoom}, 
    height=${popupHeight / systemZoom}, 
    top=${top}, 
    left=${left}
    `
    );
    if (window?.focus as any) newWindow?.focus();
};
