import { BuilderConfig } from '@/types/builder';

import RoomstayBlogIncrease, { RoomstayBlogIncreaseProps } from './roomstay-blog-increase';

export const RoomstayBlogImageGridBuilderConfig = {
    name: 'Roomstay Increase',
    inputs: [
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'booking',
            type: 'text',
        },
        {
            name: 'revenue',
            type: 'text',
        },
        {
            name: 'aov',
            type: 'text',
        },
        {
            name: 'extra',
            type: 'text',
        },
        {
            name: 'description',
            type: 'text',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogIncreaseProps> = {
    component: RoomstayBlogIncrease,
    config: RoomstayBlogImageGridBuilderConfig,
};

export default builderConfig;
