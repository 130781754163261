import InfoBlock from './InfoBlock';
import { LazyLoadImage } from './lazy-load-image';

export interface InfoBlockProps {
    text: string;
    color?: 'purple' | 'pink';
}
interface HomepageDemoBlockProps {
    imageMain?: string;
    imageTop?: string;
    textCenter?: InfoBlockProps;
    textBottom?: InfoBlockProps;
}

export default function HomepageDemoBlock({ imageMain, imageTop, textCenter, textBottom }: HomepageDemoBlockProps) {
    return (
        <div className="homepage-demo-block">
            <div className="homepage-demo-block--inner">
                <div className="homepage-demo-block--background">
                    <LazyLoadImage width={670} height={486} layout="intrinsic" imageUrl={imageMain || '/assets/img/frontpage_image.webp'} alt="Booking Engine Image" unoptimized />
                </div>

                <div className="homepage-demo-block--member-only-deal">
                    <LazyLoadImage
                        width={580}
                        height={170}
                        layout="intrinsic"
                        imageUrl={imageTop || '/assets/img/member_only_deal.webp'}
                        alt="Member only Deal"
                        relativeContainer={false}
                        unoptimized
                    />
                </div>

                <div className="wrapperInfoBlock">
                    <div className="homepage-demo-block--checkout">
                        <InfoBlock text={textCenter?.text || 'Best in class UX for seamless checkout'} color={textCenter?.color || 'purple'} />
                    </div>

                    <div className="homepage-demo-block--no-exit">
                        <InfoBlock text={textBottom?.text || 'No exit click from your hotel website'} color={textBottom?.color || 'pink'} />
                    </div>
                </div>
            </div>
        </div>
    );
}
