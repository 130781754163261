import { FC } from 'react';

import styles from './roomstay-blog-increase.module.scss';

export interface RoomstayBlogIncreaseProps {
    title?: string;
    booking?: string;
    revenue?: string;
    aov?: string;
    extra?: string;
    description?: string;
}

const RoomstayBlogIncrease: FC<RoomstayBlogIncreaseProps> = (props) => {
    const { title, booking, revenue, aov, extra, description } = props;
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                With <span style={{ background: '#6333C6' }}>RoomStay</span> you can increase:
            </div>
            <div className={styles.booking}>
                <div className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path
                            d="M26.6667 9.33331H5.33335C3.86059 9.33331 2.66669 10.5272 2.66669 12V25.3333C2.66669 26.8061 3.86059 28 5.33335 28H26.6667C28.1394 28 29.3334 26.8061 29.3334 25.3333V12C29.3334 10.5272 28.1394 9.33331 26.6667 9.33331Z"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21.3334 28V6.66667C21.3334 5.95942 21.0524 5.28115 20.5523 4.78105C20.0522 4.28095 19.3739 4 18.6667 4H13.3334C12.6261 4 11.9478 4.28095 11.4477 4.78105C10.9476 5.28115 10.6667 5.95942 10.6667 6.66667V28"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span>Bookings onsite by up to </span>
                    </div>
                    <div className={styles.inputText}>36%</div>
                </div>
            </div>
            <div className={styles.booking}>
                <div className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path
                            d="M28.28 21.1866C27.4318 23.1926 26.1051 24.9603 24.4158 26.3351C22.7266 27.7098 20.7264 28.6499 18.5899 29.073C16.4535 29.4962 14.2459 29.3895 12.1602 28.7624C10.0745 28.1352 8.17418 27.0067 6.62537 25.4755C5.07656 23.9443 3.92643 22.057 3.27552 19.9786C2.62462 17.9002 2.49276 15.694 2.89147 13.5528C3.29019 11.4117 4.20734 9.40082 5.56274 7.69602C6.91814 5.99122 8.67052 4.64441 10.6667 3.77332"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M29.3333 16C29.3333 14.2491 28.9885 12.5152 28.3184 10.8976C27.6483 9.2799 26.6662 7.81004 25.4281 6.57193C24.19 5.33382 22.7201 4.35169 21.1024 3.68163C19.4848 3.01156 17.751 2.66669 16 2.66669V16H29.3333Z"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span>Revenue per session by</span>
                    </div>
                    <div className={styles.inputText}>92%</div>
                </div>
            </div>
            <div className={styles.booking}>
                <div className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <g clipPath="url(#clip0_15520_3494)">
                            <path d="M30.6666 8L18 20.6667L11.3333 14L1.33331 24" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M22.6667 8H30.6667V16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_15520_3494">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span>AOV (average order value) by</span>
                    </div>
                    <div className={styles.inputText}>46%</div>
                </div>
            </div>
            <div className={styles.extra}>
                <div className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M26.6666 16V29.3333H5.33331V16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M29.3334 9.33331H2.66669V16H29.3334V9.33331Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 29.3333V9.33331" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                            d="M16 9.33335H10C9.11597 9.33335 8.26812 8.98216 7.643 8.35704C7.01788 7.73192 6.66669 6.88408 6.66669 6.00002C6.66669 5.11597 7.01788 4.26812 7.643 3.643C8.26812 3.01788 9.11597 2.66669 10 2.66669C14.6667 2.66669 16 9.33335 16 9.33335Z"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 9.33335H22C22.8841 9.33335 23.7319 8.98216 24.357 8.35704C24.9821 7.73192 25.3333 6.88408 25.3333 6.00002C25.3333 5.11597 24.9821 4.26812 24.357 3.643C23.7319 3.01788 22.8841 2.66669 22 2.66669C17.3333 2.66669 16 9.33335 16 9.33335Z"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <span>Extra data points collected per day</span>
                    </div>
                    <div className={styles.inputText}>
                        10,000<sup>{`'`}</sup>s
                    </div>
                </div>
            </div>

            <div className={styles.description}>
                <span>Start increasing your direct revenue today by giving your guests the seamless booking experience they deserve.</span>
            </div>
        </div>
    );
};

export default RoomstayBlogIncrease;
