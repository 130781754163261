import { BuilderConfig } from '@/types/builder';
import RoomstayIntegrationModule, { RoomstayIntegrationModuleProps } from './roomstay-integration-module';

export const config = {
    name: 'Integration module',
    inputs: [
        {
            name: 'headline',
            type: 'html',
        },
        {
            name: 'title',
            type: 'text',
        },
        {
            name: 'description',
            type: 'richText',
        },
        {
            name: 'cta',
            type: 'object',
            subFields: [
                {
                    name: 'text',
                    type: 'text',
                },
                {
                    name: 'url',
                    type: 'string',
                },
            ],
        },
        {
            name: 'images',
            type: 'object',
            subFields: [
                {
                    name: 'height',
                    type: 'string',
                    enum: ['default', 'full'],
                },
                {
                    name: 'desktop',
                    type: 'file',
                },
                {
                    name: 'mobile',
                    type: 'file',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayIntegrationModuleProps> = {
    component: RoomstayIntegrationModule,
    config,
};

export default builderConfig;
