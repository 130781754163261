import classNames from 'classnames';
import React from 'react';

import styles from './text-skeleton.module.scss';

interface Props {
    className?: string;
    size: [number | string, number | string];
}

const TextSkeleton = (props: Props) => {
    const [widthText, heightText] = props.size;
    return <div className={classNames(styles.textSkeleton, props.className)} style={{ height: heightText, width: widthText }} />;
};

export default TextSkeleton;
