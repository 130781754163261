import classNames from 'classnames';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import styles from './roomstay-blog-text-wrap.module.scss';

export interface RoomstayBlogTextWrapProps {
    text?: string;
    dark?: boolean;
    noPadding?: boolean;
    positionText?: 'left' | 'center' | 'right';
}

const RoomstayBlogTextWrap: ForwardRefRenderFunction<HTMLDivElement, RoomstayBlogTextWrapProps> = ({ text, dark, noPadding, positionText }, ref) => {
    return (
        <div
            ref={ref}
            style={{
                textAlign: positionText,
            }}
            className={classNames(styles.root, { [styles.dark]: dark, [styles.noPadding]: noPadding })}
        >
            {text && (
                <div
                    className={styles.caption}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            )}
        </div>
    );
};

export default forwardRef(RoomstayBlogTextWrap);
