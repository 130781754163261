import { FC, useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

import AnimationDelay from '@/models/AnimationDelay';
import FadeUpOnVisible from '../animations/FadeUpOnVisible';
import Button from '../button';
import { LazyLoadImage } from '../lazy-load-image';

import classNames from 'classnames';
import styles from './roomstay-integration-module.module.scss';

export interface RoomstayIntegrationModuleProps {
    headline?: string;
    title?: string;
    description?: string;
    height: 'default' | 'full';
    cta?: {
        url: string;
        text: string;
    };

    images?: {
        height: 'default' | 'full';
        desktop: string;
        mobile?: string;
    };
}

const RoomstayIntegrationModule: FC<RoomstayIntegrationModuleProps> = (props) => {
    const { headline, title, height, description, cta, images } = props;

    const { width } = useWindowSize();

    const image = useMemo(() => {
        if (!images) return null;
        if (!images.mobile) return images.desktop;

        if (width > 992) return images.desktop;
        return images.mobile;
    }, [width, images]);

    return (
        <FadeUpOnVisible delay={AnimationDelay.A200}>
            <div className="container">
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div
                            className={styles.headline}
                            dangerouslySetInnerHTML={{
                                __html: headline || '',
                            }}
                        ></div>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.line}></div>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>
                        {cta?.text && (
                            <div>
                                <Button link={cta?.url} type="secondary" hideShadow>
                                    {cta?.text}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={classNames(styles.right, styles[`right-height-${images?.height}`])}>
                        {image ? <LazyLoadImage className={styles.image} imageUrl={image} layout="fill" objectFit="contain" /> : null}
                    </div>
                </div>
            </div>
        </FadeUpOnVisible>
    );
};

export default RoomstayIntegrationModule;
