import * as React from 'react';

const FacebookSocial = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.9987 2.6665H19.9987C18.2306 2.6665 16.5349 3.36888 15.2847 4.61913C14.0344 5.86937 13.332 7.56506 13.332 9.33317V13.3332H9.33203V18.6665H13.332V29.3332H18.6654V18.6665H22.6654L23.9987 13.3332H18.6654V9.33317C18.6654 8.97955 18.8058 8.64041 19.0559 8.39036C19.3059 8.14031 19.6451 7.99984 19.9987 7.99984H23.9987V2.6665Z"
                stroke="#0F172A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FacebookSocial;
