import { BuilderConfig } from '@/types/builder';

import RoomstayBlogClippedBackgroundImg, { RoomstayBlogClippedBackgroundImgProps } from './roomstay-blog-use-case-img-clipped';

export const RoomstayBlogClippedBackgroundBuilderConfig = {
    name: 'Background Image Clipped',
    inputs: [
        {
            name: 'image',
            type: 'file',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogClippedBackgroundImgProps> = {
    component: RoomstayBlogClippedBackgroundImg,
    config: RoomstayBlogClippedBackgroundBuilderConfig,
};

export default builderConfig;
