import { BuilderConfig } from '@/types/builder';

import RoomstayBlogAboutHero, { RoomstayBlogAboutHeroProps } from './roomstay-blog-about-hero';

export const RoomstayBlogAboutHeroBuilderConfig = {
    name: 'About Hero',
    inputs: [
        {
            name: 'title',
            type: 'text',
        },
        {
            name: 'description',
            type: 'text',
        },
        {
            name: 'images',
            type: 'list',
            subFields: [
                {
                    name: 'image',
                    type: 'file',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogAboutHeroProps> = {
    component: RoomstayBlogAboutHero,
    config: RoomstayBlogAboutHeroBuilderConfig,
};

export default builderConfig;
