import classNames from 'classnames';
import { FC } from 'react';

import { ArticleAuthor } from '@/types/article-author';
import LazyLoadImage from '../lazy-load-image/lazy-load-image';

import styles from './roomstay-blog-article-author.module.scss';

export interface RoomstayBlogArticleAuthorPropsProps {
    dark?: boolean;
    articleAuthor: ArticleAuthor;
    prefix?: string;
}

const RoomstayBlogArticleAuthor: FC<RoomstayBlogArticleAuthorPropsProps> = ({ dark = false, articleAuthor, prefix }) => {
    return (
        <div
            className={classNames(styles.root, {
                [styles.dark]: dark,
            })}
        >
            <div className={styles.avatar}>
                <div className={styles.zoomedImageContainer}>
                    {articleAuthor.leadImage ? (
                        <LazyLoadImage className={styles.zoomedImage} imageUrl={articleAuthor.leadImage} objectFit="cover" relativeContainer={false} />
                    ) : null}
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.name}>{[prefix, articleAuthor.name].filter(Boolean).join(' ')}</div>
                <div className={styles.jobTitle}>{articleAuthor.jobTitle}</div>
            </div>
        </div>
    );
};

export default RoomstayBlogArticleAuthor;
