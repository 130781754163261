import { BuilderConfig } from '@/types/builder';

import RoomStayBlogHeroProduct, { RoomStayBlogHeroProductProps } from './roomstay-blog-hero-product';

export const RoomStayBlogHeroProductBuilderConfig = {
    name: 'Hero Product',
    inputs: [
        {
            name: 'image',
            type: 'file',
        },
        {
            name: 'content',
            type: 'object',
            subFields: [
                {
                    name: 'subTitle',
                    type: 'html',
                    defaultValue: 'Sub Title',
                },
                {
                    name: 'title',
                    type: 'html',
                    defaultValue: 'Title',
                },
                {
                    name: 'linkUrl',
                    type: 'url',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomStayBlogHeroProductProps> = {
    component: RoomStayBlogHeroProduct,
    config: RoomStayBlogHeroProductBuilderConfig,
};

export default builderConfig;
