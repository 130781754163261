import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';
import { CSSProperties, FC, memo, useState } from 'react';

import { getPlaceholderBuilderImage, getResizedBuilderImage } from '@/helpers/BuilderImageHelper';

import styles from './lazy-load-image.module.scss';

export interface LazyLoadImageProps extends Omit<ImageProps, 'src'> {
    imageUrl: string;
    className?: string;
    ratio?: string;
    loadingPlaceholder?: boolean;
    relativeContainer?: boolean;
    blurDataColor?: 'White' | 'Black' | 'Cyan' | 'Transparent';
    containerStyle?: CSSProperties;
}

const LazyLoadImage: FC<LazyLoadImageProps> = (props) => {
    const {
        className = '',
        imageUrl,
        ratio,
        priority,
        loadingPlaceholder = true,
        relativeContainer = true,
        blurDataColor,
        style,
        containerStyle,
        onLoadingComplete,
        ...rest
    } = props;
    const [loading, setLoading] = useState(true);

    const loadingType: ImageProps['loading'] = priority ? 'eager' : 'lazy';
    let blurDataURL = loadingPlaceholder ? getPlaceholderBuilderImage(imageUrl) : undefined;
    if (blurDataColor && loadingPlaceholder) {
        switch (blurDataColor) {
            case 'White':
                // 1×1 white
                blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';
                break;
            case 'Black':
                // 1×1 black
                blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=';
                break;
            case 'Cyan':
                // 1×1 cyan
                blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM88/1nPQAIkAM92XC5AAAAAABJRU5ErkJggg==';
                break;
            default:
                // 1×1 transparent
                blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                break;
        }
    }

    return (
        <div
            className={classNames(styles.container, className, { [styles.loading]: loading }, { [styles.relative]: relativeContainer })}
            style={{ aspectRatio: ratio, ...(containerStyle || {}) }}
        >
            <Image
                objectFit="contain"
                layout="fill"
                quality={80}
                alt=""
                loading={loadingType}
                priority={priority}
                blurDataURL={blurDataURL}
                placeholder={loadingPlaceholder ? 'blur' : 'empty'}
                {...rest}
                loader={({ src, width, quality }) => {
                    return getResizedBuilderImage(src, { width, quality }) || src;
                }}
                src={imageUrl}
                style={{ transition: '0.3s', ...style }}
                onLoadingComplete={(data) => {
                    setLoading(false);
                    onLoadingComplete?.(data);
                }}
            />
        </div>
    );
};

export default memo(LazyLoadImage);
