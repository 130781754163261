import cx from 'classnames';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import ShareIcons from '../share-icons/share-icons';
import styles from './roomstay-blog-article-text-list.module.scss';

interface ContentItem {
    text: string;
    type: 'default' | 'list';
}

export interface Props {
    hideShareIcons?: boolean;
    contents?: ContentItem[];
}

const RoomstayBlogArticleTextList: FC<Props> = (props) => {
    const { hideShareIcons, contents } = props;
    const { width } = useWindowSize();

    let targetUrl;
    if (typeof window !== 'undefined') {
        targetUrl = location.href;
    }

    return (
        <div className={styles.container}>
            {hideShareIcons ? null : (
                <div className={styles.shareIcons}>
                    <ShareIcons vertical={width > 768} shareUrl={targetUrl} />
                </div>
            )}
            <div className={styles.content}>
                {contents?.map((content) => (
                    <div key={Math.random()} className={cx(styles.item, styles[content.type])}>
                        <div className={styles.arrow}></div>
                        <div>{content.text}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RoomstayBlogArticleTextList;
