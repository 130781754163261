import { withChildren } from '@builder.io/react';

import { BuilderConfig } from '@/types/builder';
import HotelBrandCarousel, { HotelBrandCarouselProps } from './roomstay-blog-hotel-brand-carousel';

export const config = {
    name: 'Hotel Carousel',
    inputs: [
        {
            name: 'hotels',
            type: 'list',
            subFields: [
                {
                    name: 'logo',
                    type: 'file',
                },
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'website',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<HotelBrandCarouselProps> = {
    component: withChildren(HotelBrandCarousel),
    config,
};

export default builderConfig;
