import { CSSProperties, FC, useState } from 'react';
import LazyLoadImage, { LazyLoadImageProps } from './lazy-load-image';

interface LazyLoadImageNaturalSizeProps extends LazyLoadImageProps {}

const LazyLoadImageNaturalSize: FC<LazyLoadImageNaturalSizeProps> = (props) => {
    const [ratio, setRatio] = useState(`1 / 1`);
    const [naturalWidth, setNaturalWidth] = useState<string | number>('100%');
    const [naturalHeight, setNaturalHeight] = useState<string | number>('100%');

    const _onLoadingComplete = ({ target }: any) => {
        const { naturalWidth, naturalHeight } = target as HTMLImageElement;

        setNaturalWidth(naturalWidth);
        setNaturalHeight(naturalHeight);
        setRatio(`${naturalWidth / naturalHeight}`);
    };

    return <LazyLoadImage {...props} onLoad={_onLoadingComplete} width={naturalWidth} height={naturalHeight} layout="intrinsic" objectFit="cover" />;
};

export default LazyLoadImageNaturalSize;
