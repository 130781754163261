import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import RoomstayBlogArticleAuthor from '@/components/roomstay-blog-article-author/roomstay-blog-article-author';
import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';

import { LazyLoadImage } from '../lazy-load-image';
import styles from './article-card.module.scss';

export interface ArticleCardConfig {
    dark?: boolean;
    large?: boolean;
    imageFullBleed?: boolean;
}

export interface ArticleCardProps extends ArticleCardConfig {
    article: Article;
}

const ArticleCard: FC<ArticleCardProps> = ({ dark = false, large = false, imageFullBleed = false, article }) => {
    const image = article.leadImage || article.leadPortraitImage;

    return (
        <Link href={article.url || ''} passHref>
            <a
                className={classNames(styles.root, {
                    [styles.dark]: dark,
                    [styles.large]: large,
                    [styles.imageFullBleed]: imageFullBleed,
                })}
            >
                <div className={styles.zoomedImageContainer}>{image ? <LazyLoadImage className={styles.zoomedImage} imageUrl={image} objectFit="cover" relativeContainer={false} /> : null}</div>
                <div className={styles.main}>
                    <div className={styles.content}>
                        <div className={styles.information}>
                            <div className={styles.category}>{article.categories?.[0]?.name}</div>
                            <div className={styles.dotDivider}>•</div>
                            <div className={styles.date}>{formatDate(article.builderModel.createdDate)}</div>
                        </div>

                        <div title={article.title} className={styles.title}>
                            {article.title}
                        </div>
                        <div title={article.description} className={styles.description}>
                            {article.description}
                        </div>
                    </div>
                    {article.author && <RoomstayBlogArticleAuthor prefix="By" articleAuthor={article.author} dark={dark} />}
                </div>
            </a>
        </Link>
    );
};

export default ArticleCard;
