import { FC } from 'react';

import { useWindowSize } from 'usehooks-ts';
import Button from '../button';
import styles from './roomstay-blog-text-footer.module.scss';

export interface RoomstayBlogTextFooterProps {
    headText?: string;
    subText?: string;
}

const RoomstayBlogTextFooter: FC<RoomstayBlogTextFooterProps> = (props) => {
    const { width: windowWidth } = useWindowSize();

    const isTablet = windowWidth <= 1200;
    return (
        <div className={styles.container}>
            {isTablet ? (
                <div className={styles.headText}>
                    Ready to take your hote<span style={{ background: '#6333c6' }}>l&#39;s booking</span> performance to the next level?
                </div>
            ) : (
                <div className={styles.headText}>
                    Ready to take your hotel&#39;s booking <span style={{ background: '#6333c6' }}>performance to the next level?</span>
                </div>
            )}

            <div className={styles.subText}>Sign up for our free booking performance audit today and see how RoomStay can help you drive more bookings and increase revenue.</div>

            <Button type="secondary" size="large" link="/book-demo">
                Book a Demo
            </Button>
        </div>
    );
};

export default RoomstayBlogTextFooter;
