import { FC } from 'react';

import { useRouter } from 'next/router';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import Button from '../button';
import { LazyLoadImage } from '../lazy-load-image';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import styles from './roomstay-blog-hero-product.module.scss';

export interface RoomStayBlogHeroProductProps {
    image?: string;
    onClick?: (e: any) => void;

    content?: {
        subTitle?: string;
        title?: string;
        linkUrl?: string;
    };
}

const RoomStayBlogHeroProduct: FC<RoomStayBlogHeroProductProps> = (props) => {
    const { image, content, onClick } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();
    const router = useRouter();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(260 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.background}
                style={{
                    backgroundColor: '#F3F3F5',
                    maxHeight: `calc(100% - ${(height / 7) * 2}px)`,
                }}
            />

            <div className={styles.wrapperContainer}>
                <div className={styles.wrapperContent} ref={introRef}>
                    <div className={styles.contentLeft}>
                        <div className={styles.wrapperImage}>{image ? <LazyLoadImage objectFit="contain" imageUrl={image} /> : null}</div>
                    </div>
                    <div className={styles.contentText}>
                        <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: content?.subTitle || '' }}></div>
                        <div className={styles.title} dangerouslySetInnerHTML={{ __html: content?.title || '' }}></div>

                        <div className={styles.button}>
                            <Button theme="blackCurrant" link={content?.linkUrl}>
                                <div className={styles.content}>
                                    <span>Book a Demo</span>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66667 16H25.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 6.66675L25.3333 16.0001L16 25.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogHeroProduct;
