import { calculateRevParAverageDaily, calculateRevParTotalRoom } from '@/utils/CalculateRevPar';
import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import styles from './roomstay-blog-revpar.module.scss';

export interface RoomstayBlogRevParProps {
    spaceDefault?: boolean;
}

const RoomstayBlogRevPar: FC<RoomstayBlogRevParProps> = (props) => {
    const { spaceDefault } = props;

    const [dailyRate, setDailyRate] = useState(0);
    const [occupancyRate, setOccupancy] = useState(0);
    const [Period, setPeriod] = useState(0);
    const [totalRoomRevenue, setTotalRoomRevenue] = useState(0);
    const [totalNumberOfRooms, setTotalNumberOfRooms] = useState(0);

    const onChangeDailyRage = (e: ChangeEvent<HTMLInputElement>) => {
        setDailyRate(Number(e.target.value));
    };

    const onChangeOccupancyRate = (e: ChangeEvent<HTMLInputElement>) => {
        setOccupancy(Number(e.target.value));
    };

    const onChangePeriod = (e: ChangeEvent<HTMLInputElement>) => {
        setPeriod(Number(e.target.value));
    };

    const onChangeRoomRevenue = (e: ChangeEvent<HTMLInputElement>) => {
        setTotalRoomRevenue(Number(e.target.value));
    };

    const onChangeNumberOfRooms = (e: ChangeEvent<HTMLInputElement>) => {
        setTotalNumberOfRooms(Number(e.target.value));
    };

    const totalRevPar = calculateRevParAverageDaily(dailyRate, occupancyRate);
    const totalRevParTotalRoom = calculateRevParTotalRoom(totalRoomRevenue, totalNumberOfRooms, Period);

    return (
        <div
            className={classNames(styles.container, {
                [styles.spaceDefault]: spaceDefault,
            })}
        >
            <div
                className={classNames(styles.methodOne, {
                    [styles.spaceDefault]: spaceDefault,
                })}
            >
                <div className={styles.title}>Method #1</div>
                <div className={styles.subTitle}>Calculate RevPAR using Average Daily Rate and Occupancy Rate.</div>
                <div className={styles.groupRevPar}>
                    <div className={styles.wrapper}>
                        <div className={styles.text}>Average Daily Rate (ADR)</div>
                        <div className={styles.wrapperInput}>
                            <span className={styles.prefix}>ADR</span>
                            <input className={styles.input} type="number" onChange={onChangeDailyRage}></input>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.text}>Occupancy Rate (in percentage)</div>
                        <div className={styles.wrapperInput}>
                            <span className={styles.prefix}>%</span>
                            <input className={styles.input} type="number" onChange={onChangeOccupancyRate}></input>
                        </div>
                    </div>
                </div>
                <div className={styles.totalRevPar}>
                    <div className={styles.text}>Your RevPAR is:</div>
                    <div className={styles.number}>{totalRevPar}</div>
                </div>
            </div>

            <div
                className={classNames(styles.methodTow, {
                    [styles.spaceDefault]: spaceDefault,
                })}
            >
                <div className={styles.title}>Method #2</div>
                <div className={styles.subTitle}>Calculate RevPAR using the Total Room Revenue and Available Rooms.</div>
                <div className={styles.groupRevPar}>
                    <div className={styles.wrapper}>
                        <div className={styles.text}>Period (in days)</div>
                        <div className={styles.wrapperInput}>
                            <span className={styles.prefix}>days</span>
                            <input className={styles.input} type="number" onChange={onChangePeriod}></input>
                        </div>
                    </div>

                    <div className={styles.wrapper}>
                        <div className={styles.text}>Total room revenue</div>
                        <div className={styles.wrapperInput}>
                            <input className={styles.input} type="number" onChange={onChangeRoomRevenue}></input>
                        </div>
                    </div>

                    <div className={styles.wrapper}>
                        <div className={styles.text}>Total number of rooms</div>
                        <div className={styles.wrapperInput}>
                            <span className={styles.prefix}>rooms</span>
                            <input className={styles.input} type="number" onChange={onChangeNumberOfRooms}></input>
                        </div>
                    </div>
                </div>
                <div className={styles.totalRevPar}>
                    <div className={styles.text}>Your RevPAR is:</div>
                    <div className={styles.number}>{totalRevParTotalRoom}</div>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogRevPar;
