import classNames from 'classnames';
import { FC } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import CardIntegrations from './card-integrations/cardIntegrations';
import styles from './roomstay-blog-integrations.module.scss';

export interface RoomstayBlogIntegrationsProps {
    title: string;
    description: string;
    cards?: {
        logo?: string;
        logoHeight?: number;
        title?: string;
        description?: string;
        linkTo?: string;
    }[];
}

const RoomstayBlogIntegrations: FC<RoomstayBlogIntegrationsProps> = (props) => {
    const { cards = [] } = props;
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();
    const [containerRef, { height }] = useElementSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[]}
                className={styles.background}
                style={{
                    backgroundColor: '#F3F3F5',
                    maxHeight: `${height / 2}px`,
                }}
            />
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(515 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.backgroundClipped)}
                style={{
                    backgroundColor: '#F3F3F5',
                    maxHeight: `${windowWidth > 768 ? introHeight + 130 + 100 : introHeight + 77 + 60}px`,
                    marginTop: '-2px',
                }}
            />

            <div ref={introRef} className={styles.textContent}>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: props.title }} />
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>
            <div className={styles.cardContent}>
                {cards.map((card, index) => {
                    return (
                        <div className={styles.cards} key={new Date().getTime() + index}>
                            <CardIntegrations logo={card.logo} logoHeight={card.logoHeight} title={card.title} description={card.description} linkTo={card.linkTo} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RoomstayBlogIntegrations;
