import { FC, useState } from 'react';
import { Autoplay, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './roomstay-blog-sem-list.module.scss';
import SemCard, { SemCardProps } from './sem-card/sem-card';

export interface RoomstayBlogSemListProps {
    cards: SemCardProps[];
}

const options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    allowTouchMove: true,
    noSwiping: false,

    effect: 'slide',
    cssMode: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    watchSlidesProgress: true,
    freeMode: true,
    pagination: {
        clickable: true,
    },

    modules: [EffectFade, Navigation, Pagination, Autoplay],

    breakpoints: {
        992: {
            watchOverflow: true,
        },

        768: {
            spaceBetween: 20,
        },
    },
};

const RoomstayBlogSemList: FC<RoomstayBlogSemListProps> = (props) => {
    const { cards } = props;
    const [showBgShadowRight, setShowBgShadowRight] = useState(false);
    const [showBgShadowLeft, setShowBgShadowLeft] = useState(true);

    if (!cards?.length) return <div>123123</div>;

    return (
        <div className={styles.container}>
            <div className="swiper-button-next ">
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <path d="M7.16675 16H25.8334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.5 6.66675L25.8333 16.0001L16.5 25.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <div className="swiper-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <path d="M7.16675 16H25.8334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.5 6.66675L25.8333 16.0001L16.5 25.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <div className={styles.box}>
                {!showBgShadowRight && <div className={styles.bgShadowRight}></div>}
                <div className={styles.bgShadowLeft}></div>
                {/* {showBgShadowLeft && <div className={styles.bgShadowLeft}></div>} */}

                <Swiper
                    {...options}
                    onSlideChange={(swiper) => {
                        setShowBgShadowRight(swiper.isEnd);
                        setShowBgShadowLeft(swiper.isEnd);
                    }}
                >
                    {cards.map((card) => (
                        <SwiperSlide key={card.title}>
                            <div className={styles.item}>
                                <SemCard key={Math.random()} image={card.image} title={card.title} description={card.description} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default RoomstayBlogSemList;
