import { FC } from 'react';

import { useElementSize, useWindowSize } from 'usehooks-ts';
import { LazyLoadImage } from '../lazy-load-image';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import styles from './roomstay-blog-use-case-img-clipped.module.scss';

export interface RoomstayBlogClippedBackgroundImgProps {
    image: string;
}

const RoomstayBlogClippedBackgroundImg: FC<RoomstayBlogClippedBackgroundImgProps> = (props) => {
    const { image } = props;
    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(171 / 1920),
                        position: POSITION.TopLeft,
                    },
                ]}
                className={styles.background}
                style={{
                    backgroundColor: '#ffffff',
                    maxHeight: `${windowWidth > 768 ? introHeight + 230 : introHeight + 90}px`,
                    transform: 'rotate(180deg)',
                }}
            />

            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(171 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.backgroundBottom}
                style={{
                    backgroundColor: '#ffffff',
                    maxHeight: `${windowWidth > 768 ? introHeight + 240 : introHeight + 90}px`,
                    transform: 'rotate(180deg)',
                    marginTop: '0px',
                }}
            />
            <LazyLoadImage className={styles.wrapperImg} imageUrl={image} objectFit="cover" />
        </div>
    );
};

export default RoomstayBlogClippedBackgroundImg;
