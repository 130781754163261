import { FC } from 'react';

import styles from './roomstay-blog-text-caption.module.scss';

export interface Props {
    text: string;
}

const RoomstayBlogTextCaption: FC<Props> = (props) => {
    const { text } = props;

    return (
        <div className={styles.container}>
            <div className={styles.textCaption} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default RoomstayBlogTextCaption;
