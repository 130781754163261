import { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import Link from 'next/link';
import { FC } from 'react';

import { Article } from '@/types/article';
import { LazyLoadImage } from '../lazy-load-image';
import HomeHeroSkeleton from '../skeleton/home-hero-skeleton/home-hero-skeleton';
import BlogHomeHeroArticleCarousel from './blog-home-hero-article-carousel/blog-home-hero-article-carousel';
import { RoomstayBlogHomeHeroBuilderConfig } from './roomstay-blog-blog-hero.builder-component';

import styles from './roomstay-blog-blog-hero.module.scss';

export interface SSProps {
    leadArticle?: Article;
    articles?: Article[];
    builderState?: BuilderComponentState;
}

export interface BuilderProps {}

export interface Props extends SSProps, BuilderProps {}

const BlogHomeHero: FC<Props> = (props) => {
    const { builderState } = props;
    const config = builderState?.state[RoomstayBlogHomeHeroBuilderConfig.name];
    const leadArticle = config?.leadArticle;
    const articles = config?.articles || [];

    if (!leadArticle) return <HomeHeroSkeleton />;

    const leadImage = leadArticle?.leadPortraitImage || leadArticle?.leadImage;

    return (
        <div className={styles.container}>
            <div className={styles.background}>{leadImage ? <LazyLoadImage imageUrl={leadImage} objectFit="cover" loadingPlaceholder={false} /> : null}</div>
            <div className={styles.content}>
                <div className={styles.titleBox}>
                    <Link href={leadArticle?.url} passHref>
                        <a title={leadArticle?.title} className={styles.title}>
                            {leadArticle?.title}
                        </a>
                    </Link>
                    <div className={styles.arrow}></div>
                    <div className={styles.divider}></div>
                </div>
                {articles.length ? <BlogHomeHeroArticleCarousel articles={articles} /> : null}
            </div>
        </div>
    );
};

export default BlogHomeHero;
