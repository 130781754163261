import { BuilderConfig } from '@/types/builder';
import RoomstayBlogHelpdeskForm, { RoomstayBlogHelpdeskFormProps } from './roomstay-blog-helpdesk-form';

export const config = {
    name: 'Helpdesk Form',
    inputs: [
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'description',
            type: 'string',
        },
        {
            name: 'successMessage',
            type: 'string',
        },
        {
            name: 'textColorInput',
            type: 'color',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHelpdeskFormProps> = {
    component: RoomstayBlogHelpdeskForm,
    config,
};

export default builderConfig;
