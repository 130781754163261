import { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import React, { FC, useRef, useState } from 'react';

import ArticleGrid, { defaultTemplates } from '@/components/article-grid/article-grid';
import { RoomstayBlogAllArticlesConfig } from '@/components/roomstay-blog-all-articles/roomstay-blog-all-articles.builder-component';
import { Article } from '@/types/article';
import { getItemsPerPage, getMoreArticles } from '@/utils/Article';

import styles from './roomstay-blog-all-articles.module.scss';

export interface SSProps {
    fixedArticles?: Article[];
    builderState?: BuilderComponentState;
    currentRow?: number;
    defaultOffset?: number;
    isLoading?: boolean;
}

export interface BuilderProps {
    title?: string;
    offset?: number;
}

export interface Props extends BuilderProps, SSProps {}

export const ROWS = 3;
export const DEFAULT_OFFSET = 7;
export const TEMPLATE = defaultTemplates;

const getDefaultValue = <T,>(defaultValue: T, value?: T): T => (value === undefined ? defaultValue : value);

const DijiBlogAllArticles: FC<Props> = ({ title, offset: offsetProp = DEFAULT_OFFSET, builderState }) => {
    const state = builderState?.state[RoomstayBlogAllArticlesConfig.name];
    const [articles, setArticles] = useState<Article[]>(getDefaultValue<Article[]>([], state?.fixedArticles));
    const [offset, setOffsetArticle] = useState<number>(getDefaultValue<number>(offsetProp, state?.defaultOffset));
    const [isLoadingMoreArticles, setIsLoadingMoreArticles] = useState<boolean>(getDefaultValue<boolean>(true, state?.isLoading));
    const currentRow = useRef<number>(getDefaultValue(0, state?.currentRow));

    const loadMoreArticles = async (currentOffset: number) => {
        await setIsLoadingMoreArticles(true);
        const newArticles = await getMoreArticles(currentOffset, getItemsPerPage(ROWS, currentRow.current, TEMPLATE));
        setArticles((articles || []).concat(newArticles));
        await setIsLoadingMoreArticles(false);
        currentRow.current += ROWS;
    };

    if (articles && articles.length === 0) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>{title}</div>
            <ArticleGrid
                articles={articles || []}
                isLoadingMoreArticles={isLoadingMoreArticles}
                setOffsetArticle={setOffsetArticle}
                offsetArticle={offset}
                itemsPerPage={getItemsPerPage(ROWS, currentRow.current, TEMPLATE)}
                initialOffsetArticle={offsetProp}
                templates={TEMPLATE}
                onLoadMoreClick={loadMoreArticles}
            />
        </div>
    );
};

export default DijiBlogAllArticles;
