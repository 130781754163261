import { BuilderConfig } from '@/types/builder';

import RoomStayBlogHeroPricing, { HeroPricingProps } from './roomstay-blog-hero-pricing';

export const RoomstayBlogHeroPricingBuilderConfig = {
    name: 'Hero Pricing',
    inputs: [
        {
            name: 'subTitle',
            type: 'string',
        },
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'subDescription',
            type: 'string',
        },
        {
            name: 'description',
            type: 'html',
        },
        {
            name: 'cardPricing',
            type: 'object',
            subFields: [
                {
                    name: 'subTitle',
                    type: 'html',
                },
                {
                    name: 'title',
                    type: 'html',
                },
                {
                    name: 'description',
                    type: 'html',
                },
                {
                    name: 'link',
                    type: 'url',
                },
                {
                    name: 'successMessage',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<HeroPricingProps> = {
    component: RoomStayBlogHeroPricing,
    config: RoomstayBlogHeroPricingBuilderConfig,
};

export default builderConfig;
