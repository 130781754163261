import { InputGroup } from '@/components/Inputs/InputGroup';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../button';

export interface RoomstayBlogBookDemoFormProps {
    title?: string;
    description?: string;
    successMessage?: string;
}

const RoomstayBlogBookDemoForm: FC<RoomstayBlogBookDemoFormProps> = ({ title, description, successMessage }) => {
    const formMethods = useForm({
        mode: 'onChange',
    });

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = async () => {
        setSending(true);

        const values = formMethods.getValues();

        try {
            const response = await fetch('/api/book-demo', {
                method: 'POST',
                body: JSON.stringify({
                    forename: values.forename,
                    surname: values.surname,
                    subject: values.subject,
                    number: values.number,
                    email: values.email,
                    message: values.message,
                }),
            });

            formMethods.reset()
            setSuccess(response.ok);
            setSending(false);
        } catch (ex) {
            setSending(false);
        }
    };

    return (
        <div className="centered-body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <div className="u-marg-bottom">
                                <h1 className="headline --primary-gradient u-text-center">{title}</h1>
                            </div>
                            <div className="u-marg-bottom--largest">
                                <p className="body-text u-text-center">{description}</p>
                            </div>
                            <div className="helpdesk-inputs">
                                <div className="u-marg-bottom">
                                    <InputGroup dimensions="2/2">
                                        <input
                                            type="text"
                                            required
                                            className="text-input"
                                            placeholder="First Name"
                                            tabIndex={1}
                                            {...formMethods.register('forename', { required: true })}
                                        />
                                        <input
                                            type="text"
                                            required
                                            className="text-input"
                                            placeholder="Last Name"
                                            tabIndex={2}
                                            {...formMethods.register('surname', { required: true })}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="u-marg-bottom">
                                    <input
                                        type="text"
                                        required
                                        className="text-input"
                                        placeholder="Your email"
                                        tabIndex={3}
                                        {...formMethods.register('email', { required: true })}
                                    />
                                </div>
                                <div className="u-marg-bottom">
                                    <input
                                        type="text"
                                        required
                                        className="text-input"
                                        placeholder="Your Phone number"
                                        tabIndex={4}
                                        {...formMethods.register('number', { required: true })}
                                    />
                                </div>
                                <div className="u-marg-bottom">
                                    <input
                                        type="text"
                                        required
                                        className="text-input"
                                        placeholder="Subject"
                                        tabIndex={5}
                                        {...formMethods.register('subject', { required: true })}
                                    />
                                </div>
                                <div className="u-marg-bottom">
                                    <textarea className="text-input" placeholder="Your Message" tabIndex={6} {...formMethods.register('message', { required: true })} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <strong className="body-text --small --cyan u-pad-right" style={{ opacity: success ? 1 : 0 }}>
                                    {successMessage || 'Thanks! Message sent.'}
                                </strong>
                                <Button
                                    type="primary"
                                    loading={sending}
                                    htmlType="submit"
                                    onSubmit={async () => {
                                        await formMethods.handleSubmit(onSubmit)();
                                    }}
                                    style={{ flexShrink: 1, display: 'flex', whiteSpace: 'nowrap' }}
                                >
                                    Book a Demo
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogBookDemoForm;
