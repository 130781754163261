import { Builder } from '@builder.io/react';
import { BuilderComponentState } from '@builder.io/react/dist/types/src/components/builder-component.component';
import classNames from 'classnames';
import Link from 'next/link';
import { FC, useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import ClippedBox, { POSITION } from '@/components/roomstay-blog-image-block/clipped-box';
import { formatDate } from '@/helpers/DateHelper';
import { Article } from '@/types/article';
import { BuilderReference, BUILDER_MODEL } from '@/types/builder.d';
import { getHighlightArticle } from '@/utils/Article';
import { LazyLoadImage } from '../lazy-load-image';
import { RoomstayBlogHighlightArticleConfig } from './roomstay-blog-highlight-article.builder-component';

import styles from './roomstay-blog-highlight-article.module.scss';

export interface SSProps {
    fixedArticle: Article | null;
    builderState?: BuilderComponentState;
    topColor?: string;
    bottomColor?: string;
}

export interface BuilderProps {
    article?: BuilderReference<BUILDER_MODEL.ARTICLE>;
    arrowText?: string;
}

const DESKTOP_ANGLE_TAN = 85 / 1920;
const MOBILE_ANGLE_TAN = 35 / 375;

export interface Props extends SSProps, BuilderProps {}

const RoomstayBlogHighlightArticle: FC<Props> = (props) => {
    const { article: builderArticle, fixedArticle, arrowText = 'Recommended', builderState, topColor } = props;
    const { width } = useWindowSize();
    const isMobile = width < 992;
    const [article, setArticle] = useState<Article | null>(fixedArticle || builderState?.state?.[RoomstayBlogHighlightArticleConfig.name]?.fixedArticle || null);

    const getDataClientSide = useCallback(async (): Promise<void> => {
        if (builderArticle && Builder.isEditing) {
            const article = await getHighlightArticle(builderArticle);
            setArticle(article);
        } else {
            setArticle(fixedArticle);
        }
    }, [builderArticle, fixedArticle]);

    useEffect(() => {
        getDataClientSide();
    }, [getDataClientSide]);

    if (!article) return null;

    const leadImage = article.leadPortraitImage || article.leadImage;

    return (
        <div className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(isMobile ? MOBILE_ANGLE_TAN : DESKTOP_ANGLE_TAN),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.topBackground)}
                style={{ backgroundColor: topColor }}
            />
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(isMobile ? MOBILE_ANGLE_TAN : DESKTOP_ANGLE_TAN),
                        position: POSITION.TopLeft,
                    },
                    {
                        rad: Math.atan(isMobile ? MOBILE_ANGLE_TAN : DESKTOP_ANGLE_TAN),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.mainBackground)}
            />
            <Link href={article.url || '/'} passHref>
                <a className={styles.box} aria-label={article.url}>
                    <div className={styles.left}>
                        <div className={styles.imageContainer}>{leadImage ? <LazyLoadImage className={styles.image} imageUrl={leadImage} objectFit="cover" /> : null}</div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.textCaption}>
                            <span>{arrowText}</span>
                            <span className={styles.arrow} />
                        </div>
                        <div className={styles.info}>
                            <span className={styles.category}>{article.categories[0]?.name}</span>
                            &nbsp;&nbsp;&nbsp;<span className={styles.dot}>&#8226;</span>&nbsp;&nbsp;&nbsp;
                            <span className={styles.date}>{article.lastUpdated ? formatDate(article.lastUpdated) : ''}</span>
                        </div>
                        <div title={article.title} className={styles.title}>
                            {article.title}
                        </div>
                        {article.author ? (
                            <div className={styles.author}>
                                <div className={styles.avatarContainer}>
                                    {article.author.leadImage ? <LazyLoadImage className={styles.avatar} imageUrl={article.author.leadImage} /> : null}
                                </div>
                                {/* </div> */}
                                <div>
                                    <div className={styles.name}>{article.author.name}</div>
                                    <div className={styles.jobTitle}>{article.author.jobTitle}</div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </a>
            </Link>
        </div>
    );
};

export default RoomstayBlogHighlightArticle;
