import RoomstayBlogBookDemoForm, { RoomstayBlogBookDemoFormProps } from './roomstay-blog-book-demo-form';
import { BuilderConfig } from '@/types/builder';

export const config = {
    name: 'Book Demo Form',
    inputs: [
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'description',
            type: 'string',
        },
        {
            name: 'successMessage',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogBookDemoFormProps> = {
    component: RoomstayBlogBookDemoForm,
    config,
};

export default builderConfig;
