import classNames from 'classnames';
import { FC } from 'react';

import { LazyLoadImage } from '@/components/lazy-load-image';

import styles from './image-grid.module.scss';

export interface ImageGridProps {
    images: {
        image: string;
    }[];
}

const ImageGrid: FC<ImageGridProps> = (props) => {
    const { images = [] } = props;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.col}>
                    <div className={classNames(styles.row, styles['row--1'])}>
                        <div className={styles.wrapImg}>{images[0]?.image ? <LazyLoadImage imageUrl={images[0]?.image} alt="" layout="fill" objectFit="cover" /> : null}</div>
                        <div className={classNames(styles.squares, 'd-flex', 'justify-content-end', 'align-items-end')}>
                            <div className={classNames(styles.squareContainer, styles.fillCyan, styles.mdHide)} />
                            <div className={classNames(styles.squareContainer, styles.fillAztecPurple, styles.mdHide)} />
                            <div className={classNames(styles.squareContainer, styles.fillWhite, styles.mdShow, styles.maxHeight50)} />
                        </div>
                    </div>

                    <div className={classNames(styles.row, styles['row--2'])}>
                        <div className={styles.wrapImg}>{images[1]?.image ? <LazyLoadImage imageUrl={images[1]?.image} alt="" layout="fill" objectFit="cover" /> : null}</div>
                        <div className={styles.wrapImg}>{images[2]?.image ? <LazyLoadImage imageUrl={images[2]?.image} alt="" layout="fill" objectFit="cover" /> : null}</div>
                    </div>

                    <div className={classNames(styles.row, styles['row--3'])}>
                        <div className={classNames(styles.row, styles['row--3--1'])}>
                            <div className={classNames(styles.wrapImg)}>
                                {images[3]?.image ? <LazyLoadImage imageUrl={images[3]?.image} alt="" layout="fill" objectFit="cover" /> : null}
                            </div>
                            <div className={classNames(styles.squares, 'd-flex', 'justify-content-md-end', 'justify-content-start', 'align-items-start', 'flex-column')}>
                                <div className={classNames(styles.squareContainer, styles.fillCyan, styles.mdShow)} />
                                <div className={classNames(styles.squareContainer, styles.fillAztecPurple, styles.mdShow)} />
                                <div className={classNames(styles.squareContainer, styles.fillWhite, styles.mdHide)} />
                            </div>
                        </div>
                        <div className={classNames(styles.wrapImg, styles.mdHide)}>
                            {images[4]?.image ? <LazyLoadImage imageUrl={images[4]?.image} alt="" layout="fill" objectFit="cover" /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageGrid;
