import { FC } from 'react';

import styles from './roomstay-blog-summing-up.module.scss';

interface SummingUpItem {
    title: string;
    description: string;
}

export interface Props {
    items?: SummingUpItem[];
}

const RoomstayBlogSummingUp: FC<Props> = (props) => {
    const { items = [] } = props;

    return (
        <div className={styles.container}>
            <div className={styles.summingUp}>Summing up...</div>
            <div className={styles.items}>
                {items.map((item, index) => (
                    <div key={item.title} className={styles.item}>
                        <div className={styles.number}>{index + 1}</div>
                        <div className={styles.content}>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.description}>{item.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RoomstayBlogSummingUp;
