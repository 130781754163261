import React, { FC } from 'react';
import FadeUpOnVisible from '@/components/animations/FadeUpOnVisible';

export interface RoomstayBlogHeadlineProps {
    text?: string;
}

const RoomstayBlogHomeHeadline: FC<RoomstayBlogHeadlineProps> = ({ text }) => {
    return (
        <div className="container">
            <FadeUpOnVisible>
                <div className="d-flex align-items-center flex-column mt-5">
                    <h2
                        className="headline --centered --larger"
                        dangerouslySetInnerHTML={{
                            __html: text || '',
                        }}
                    />
                </div>
            </FadeUpOnVisible>
        </div>
    );
};

export default RoomstayBlogHomeHeadline;
