import { FC } from 'react';

import BookADemo from '../book-a-demo';
import RoomstayBlogIncrease from '../roomstay-blog-increase/roomstay-blog-increase';
import RoomstaySemHelpdeskForm from '../roomstay-blog-sem-helpdesk-form/roomstay-blog-sem-helpdesk-form';
import styles from './roomstay-blog-footer-layout-form.module.scss';

export interface RoomstayFooterLayoutFormProps {
    buttonText?: string;
    showMessage?: boolean;
    padding?: string;
    fontSizeTitle?: string;
    titleForm?: string;
    showTextFooter?: boolean;
    headlineFooter?: string;
    subHeadlineFooter?: string;
    buttonNameFooter?: string;
    buttonLinkFooter?: string;
}

const RoomstayBlogFooterLayoutForm: FC<RoomstayFooterLayoutFormProps> = (props) => {
    const {
        buttonText,
        titleForm,
        showMessage = true,
        padding = 'none',
        fontSizeTitle = 'small',
        showTextFooter,
        headlineFooter,
        subHeadlineFooter,
        buttonNameFooter,
        buttonLinkFooter,
    } = props;
    return (
        <div className="footer-book-a-demo-form-container">
            <div className="footer-book-a-demo-form--background-container">
                <svg className="background--curve" width="100%" height="100%" viewBox="0 0 1919 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M-1 50C-1 50 479 0 959 0C1439 0 1919 50 1919 50H-1Z" fill="#7C39FF" />
                </svg>
                <div className="background--main"></div>
            </div>
            {showTextFooter ? (
                <div className={styles.wrapperText}>
                    <BookADemo headline={headlineFooter} subHeadline={subHeadlineFooter} buttonName={buttonNameFooter} buttonLink={buttonLinkFooter} />
                </div>
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.increase}>
                        <RoomstayBlogIncrease />
                    </div>

                    <div className={styles.divider}></div>

                    <div className={styles.mail}>
                        <RoomstaySemHelpdeskForm titleForm={titleForm} fontSizeTitle={'small'} padding={'none'} buttonText={buttonText} showMessage={showMessage} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RoomstayBlogFooterLayoutForm;
