import { FC } from 'react';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import classNames from 'classnames';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import FadeUpOnVisible from '../animations/FadeUpOnVisible';
import styles from './roomstay-blog-sem-theme.module.scss';

export interface RoomstayBlogSemThemeProps {
    backgroundColor?: string;
    children: React.ReactNode;
}

const RoomstayBlogSemTheme: FC<RoomstayBlogSemThemeProps> = (props) => {
    const { backgroundColor, children } = props;
    const { width: windowWidth } = useWindowSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const [containerRef, { height }] = useElementSize();

    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth <= 991;

    return (
        <div className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: isMobile ? Math.tan(101 / 375) : Math.tan(531 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.backgroundClippedBottom)}
                style={{
                    backgroundColor: `${props.backgroundColor ?? '#1C1624'}`,
                    maxHeight: isMobile ? 300 : isTablet ? 400 : 600,
                    // transform: 'rotate(80deg)',
                    marginTop: -1,
                }}
            />

            <div className={styles.children}>
                <FadeUpOnVisible>{children}</FadeUpOnVisible>
            </div>
        </div>
    );
};

export default RoomstayBlogSemTheme;
