import { withChildren } from '@builder.io/react';

import { BuilderConfig } from '@/types/builder';

import RoomStayBlogListCard, { Props } from './roomstay-blog-list-card';

export const RoomstayBlogListCardBuilderConfig = {
  name: 'List Card',
  inputs: [
    {
      name: 'cards',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'richText',
        },
        {
          name: 'description',
          type: 'string',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: withChildren(RoomStayBlogListCard),
  config: RoomstayBlogListCardBuilderConfig,
};

export default builderConfig;
