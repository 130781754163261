import { ImgHTMLAttributes, useEffect, useState } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

import { getPlaceholderBuilderImage, getResizedBuilderImage, ImageResizeParameters } from '@/helpers/BuilderImageHelper';

interface RawLazyLoadImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>, Pick<IntersectionOptions, 'threshold'> {
    imageUrl: string | undefined;
    imageWidth?: number;
    priority?: boolean;
    onLoaded?: () => void;
}

export const RawLazyLoadImage = (props: RawLazyLoadImageProps) => {
    const { className = '', imageUrl, imageWidth, priority, threshold = 0, onLoaded, ...rest } = props;

    const { ref, inView } = useInView({ threshold });

    const [wasSeen, setWasSeen] = useState(false);
    const [currentImage, setCurrentImage] = useState(getPlaceholderBuilderImage(imageUrl));

    useEffect(() => {
        if ((priority || inView) && !wasSeen) {
            setWasSeen(true);
            onLoaded?.();
        }
    }, [inView, priority]);

    useEffect(() => {
        let cancelled = false;

        const resizeParams: ImageResizeParameters = {};

        if (imageWidth && imageWidth > 1) {
            resizeParams.width = imageWidth;
        }

        if (wasSeen) {
            const resizedImage = getResizedBuilderImage(imageUrl, resizeParams);

            const loadingImage = new Image();
            loadingImage.onload = () => {
                if (!cancelled) {
                    setCurrentImage(resizedImage);
                }
            };
            loadingImage.src = resizedImage ?? '';
        }

        return () => {
            cancelled = true;
        };
    }, [wasSeen, imageUrl, imageWidth]);

    // eslint-disable-next-line @next/next/no-img-element
    return <img ref={ref} alt="" width="100%" height="auto" className={className} {...rest} src={currentImage} />;
};
