import React from 'react';

const LinkedInSocial = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.332 10.6665C23.4538 10.6665 25.4886 11.5094 26.9889 13.0096C28.4892 14.5099 29.332 16.5448 29.332 18.6665V27.9998H23.9987V18.6665C23.9987 17.9593 23.7177 17.281 23.2176 16.7809C22.7176 16.2808 22.0393 15.9998 21.332 15.9998C20.6248 15.9998 19.9465 16.2808 19.4464 16.7809C18.9463 17.281 18.6654 17.9593 18.6654 18.6665V27.9998H13.332V18.6665C13.332 16.5448 14.1749 14.5099 15.6752 13.0096C17.1755 11.5094 19.2103 10.6665 21.332 10.6665V10.6665Z"
                stroke="#0F172A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M8.0013 12H2.66797V28H8.0013V12Z" stroke="#0F172A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M5.33464 7.99984C6.80739 7.99984 8.0013 6.80593 8.0013 5.33317C8.0013 3.86041 6.80739 2.6665 5.33464 2.6665C3.86188 2.6665 2.66797 3.86041 2.66797 5.33317C2.66797 6.80593 3.86188 7.99984 5.33464 7.99984Z"
                stroke="#0F172A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LinkedInSocial;
