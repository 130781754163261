import { LazyLoadImage } from '@/components/lazy-load-image';
import { FC } from 'react';
import styles from './sem-card.module.scss';

export interface SemCardProps {
    image?: string;
    title?: string;
    description?: string;
}

const SemCard: FC<SemCardProps> = (props) => {
    const { image, title, description } = props;
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>{image && <LazyLoadImage className={styles.image} imageUrl={image} alt="" />}</div>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }} />
            <div className={styles.divider} />
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }} />
        </div>
    );
};

export default SemCard;
