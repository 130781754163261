import { BuilderConfig } from '@/types/builder';
import ButtonBookADemo, { ButtonBookADemoProps } from './index';

export const RoomstayBlogButtonCustomConfig = {
    name: 'Button Custom',
    inputs: [
        {
            name: 'buttonName',
            type: 'string',
        },
        {
            name: 'theme',
            type: 'string',
            enum: ['transparent', 'black', 'purple'],
        },

        {
            name: 'sectionId',
            type: 'string',
        },

        {
            name: 'color',
            type: 'string',
            enum: ['black', 'white'],
        },
        {
            name: 'border',
            type: 'string',
            enum: ['transparent', 'color'],
        },
        {
            name: 'iconFillColor',
            type: 'boolean',
        },
    ],
};

const builderConfig: BuilderConfig<ButtonBookADemoProps> = {
    component: ButtonBookADemo,
    config: RoomstayBlogButtonCustomConfig,
};

export default builderConfig;
