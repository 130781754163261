import AnimationDelay from '@/models/AnimationDelay';
import classNames from 'classnames';
import { FC } from 'react';
import FadeUpOnVisible from '../animations/FadeUpOnVisible';
import Button from '../button';

export interface RoomstayBlogProductCardProps {
    title?: string;
    description?: string;
    inverted?: boolean;
    graphic?: string;
    ctaButton?: {
        text?: string;
        href?: string;
    };
}

const RoomstayBlogProductsCard: FC<RoomstayBlogProductCardProps> = (props) => {
    const { title, description, ctaButton, inverted, graphic } = props;
    const productCardPositionClassName = inverted ? '--right' : '--left';
    const classes = classNames('row product-card', productCardPositionClassName);

    const firstChildClasses = classNames('col-md-6', productCardPositionClassName, {
        '--left offset-md-1': inverted,
    });

    const secondChildClasses = classNames('col-md-5', {
        '--left offset-md-1': !inverted,
    });

    return (
        <div className={classes}>
            <div className={firstChildClasses}>
                <FadeUpOnVisible delay={AnimationDelay.A200}>{graphic && <img className="w-100" src={props.graphic} alt="" />}</FadeUpOnVisible>
            </div>
            <div className={secondChildClasses}>
                <FadeUpOnVisible>
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{
                            __html: title || '',
                        }}
                    />
                    <div
                        className="body-text"
                        dangerouslySetInnerHTML={{
                            __html: description || '',
                        }}
                    />

                    {ctaButton?.text && (
                        <Button type="secondary" theme="primary" size="default" htmlType="button" link={ctaButton?.href}>
                            {ctaButton?.text || 'Inquiry Now!'}
                        </Button>
                    )}
                </FadeUpOnVisible>
            </div>
        </div>
    );
};

export default RoomstayBlogProductsCard;
