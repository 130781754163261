import { BuilderConfig } from '@/types/builder';

import RoomstayBlogQuote, { Props } from './roomstay-blog-quote';

export const RoomstayBlogQuoteBuilderConfig = {
    name: 'Quote',
    inputs: [
        {
            name: 'quote',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'author',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'jobTitle',
            type: 'string',
            defaultValue: '',
        },
        // {
        //     name: 'background',
        //     type: 'color',
        //     defaultValue: '',
        // },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogQuote,
    config: RoomstayBlogQuoteBuilderConfig,
};

export default builderConfig;
