import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHomeHero, { RoomstayBlogHomeHeroProps } from './roomstay-blog-home-hero';

export const RoomstayBlogHomeHeroBuilderConfig = {
    name: 'Home Hero',
    inputs: [
        {
            name: 'leftContentBlock',
            type: 'object',
            subFields: [
                {
                    name: 'title',
                    type: 'html',
                },
                {
                    name: 'description',
                    type: 'html',
                },
                {
                    name: 'ctaButton',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'href',
                            type: 'string',
                        },
                    ],
                },
            ],
        },
        {
            name: 'rightContentBlock',
            type: 'object',
            subFields: [
                {
                    name: 'imageMain',
                    type: 'file',
                },
                {
                    name: 'imageTop',
                    type: 'file',
                },
                {
                    name: 'textCenter',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'color',
                            type: 'string',
                            enum: ['purple', 'pink'],
                        },
                    ],
                },
                {
                    name: 'textBottom',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'color',
                            type: 'string',
                            enum: ['purple', 'pink'],
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHomeHeroProps> = {
    component: RoomstayBlogHomeHero,
    config: RoomstayBlogHomeHeroBuilderConfig,
};

export default builderConfig;
