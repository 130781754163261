import { BuilderConfig } from '@/types/builder';

import RoomStayBlogTextBooking, { Props } from './roomstay-blog-text-booking';

export const RoomStayBlogTextBookingBuilderConfig = {
    name: 'Text Booking',
    inputs: [
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },

        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomStayBlogTextBooking,
    config: RoomStayBlogTextBookingBuilderConfig,
};

export default builderConfig;
