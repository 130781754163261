import { createContext, Dispatch } from 'react';

import { Category } from '@/types/category';

interface IContextProps {
    allCategories: Category[];
    setAllCategories: Dispatch<Category[]>;
    categoriesToFilter: Category[];
    setCategoriesToFilter: Dispatch<Category[]>;
}

export const CategoryCtx = createContext({} as IContextProps);

export type LocationType = 'AU' | 'US' | 'UK';

interface ILocationContextProps {
    location: LocationType;
    setLocation: (location: LocationType) => void;
}

export const LocationCtx = createContext({} as ILocationContextProps);
