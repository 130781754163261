import { FC } from 'react';
import type { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import HotelBrandItem, { HotelBrandItemProps } from './hotel-brand-item/hotel-brand-item';
import styles from './roomstay-blog-hotel-brand-carousel.module.scss';

const options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    allowTouchMove: true,
    noSwiping: false,
    freeMode: true,
    breakpoints: {
        992: {
            watchOverflow: true,
        },
    },
};

export interface HotelBrandCarouselProps {
    hotels: HotelBrandItemProps[];
}

const HotelBrandCarousel: FC<HotelBrandCarouselProps> = (props) => {
    const { hotels } = props;

    if (!hotels?.length) return <div>123123</div>;

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <Swiper {...options}>
                    {hotels.map((hotel) => (
                        <SwiperSlide key={hotel.name}>
                            <div className={styles.item}>
                                <HotelBrandItem key={Math.random()} {...hotel} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default HotelBrandCarousel;
