import { BuilderConfig } from '@/types/builder';

import RoomstayBlogArticleTextList, { Props } from './roomstay-blog-article-text-list';

export const RoomstayBlogArticleTextListBuilderConfig = {
    name: 'Article Text List',
    inputs: [
        { name: 'hideShareIcons', type: 'boolean' },
        {
            name: 'contents',
            type: 'list',
            subFields: [
                {
                    name: 'text',
                    type: 'string',
                },
                {
                    name: 'type',
                    type: 'text',
                    enum: ['default', 'list'],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogArticleTextList,
    config: RoomstayBlogArticleTextListBuilderConfig,
};

export default builderConfig;
