import { FC } from 'react';

import { LazyLoadImage } from '../lazy-load-image';

export interface RoomstayBlogHomeImageBlockProps {
    image?: string;
    title?: string;
    description?: string;
}

const RoomstayBlogHomeImageBlock: FC<RoomstayBlogHomeImageBlockProps> = ({ image, title, description }) => {
    return (
        <div className="container --padded">
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="homepage-contained-image">
                        <LazyLoadImage
                            width={700}
                            height={467}
                            className="bordered-image"
                            objectFit="cover"
                            layout={'responsive'}
                            imageUrl={image || ''}
                            alt="What Roomstay Solves Image"
                        />
                    </div>
                </div>
                <div className="col-md-6 offset-md-1">
                    <h2
                        className="headline --secondary --with-spacer"
                        dangerouslySetInnerHTML={{
                            __html: title || '',
                        }}
                    />
                    <div
                        className="body-text"
                        dangerouslySetInnerHTML={{
                            __html: description || '',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogHomeImageBlock;
