import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHomeYoutube, { RoomstayBlogHomeYoutubeProps } from './roomstay-blog-home-youtube';

export const RoomstayBlogHomeYoutubeBuilderConfig = {
    name: 'Home Youtube',
    inputs: [
        {
            name: 'url',
            type: 'url',
        },
        {
            name: 'placeholder',
            type: 'file',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHomeYoutubeProps> = {
    component: RoomstayBlogHomeYoutube,
    config: RoomstayBlogHomeYoutubeBuilderConfig,
};

export default builderConfig;
