import classNames from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import AnimationDelay from '../../models/AnimationDelay';

interface FadeUpOnVisibleProps extends PropsWithChildren {
    delay?: AnimationDelay;
}

export default function FadeUpOnVisible(props: FadeUpOnVisibleProps) {
    const [visible, setVisible] = useState(false);
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        if (inView && !visible) {
            setVisible(true);
        }
    }, [inView]);

    const classes = classNames('a-fade-in-up', {
        '--active': visible,
        [props.delay as string]: !!props.delay,
    });

    return (
        <span ref={ref} className={classes}>
            {props.children}
        </span>
    );
}
