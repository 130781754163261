import QuotationMarks from './icons/QuotationMarks';
import Roomstay from './icons/Roomstay';

export enum IconType {
    Roomstay = 'Roomstay',
    QuotationMarks = 'QuotationMarks',
}
interface IconProps {
    type: IconType | undefined;
}

const Icon = ({ type }: IconProps) => {
    switch (type) {
        case IconType.Roomstay:
            return <Roomstay />;
        case IconType.QuotationMarks:
            return <QuotationMarks />;
        default:
            return null;
    }
};

export default Icon;
