import { BuilderConfig } from '@/types/builder';
import RoomstaySemHelpdeskForm, { RoomstayBlogSemHelpdeskFormProps } from './roomstay-blog-sem-helpdesk-form';

export const config = {
    name: 'Helpdesk Sem Form',
    inputs: [
        {
            name: 'titleForm',
            type: 'string',
        },
        {
            name: 'fontSizeTitle',
            type: 'text',
            enum: ['default', 'small'],
        },
        {
            name: 'successMessage',
            type: 'string',
        },
        {
            name: 'buttonText',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogSemHelpdeskFormProps> = {
    component: RoomstaySemHelpdeskForm,
    config,
};

export default builderConfig;
