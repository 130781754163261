import { FC } from 'react';

import styles from './roomstay-blog-list-card.module.scss';

type CardItemType = {
  title: string;
  description: string;
};

export interface Props {
  cards: CardItemType[];
}

const RoomStayBlogListCard: FC<Props> = (props) => {
  const { cards = [] } = props;

  return (
    <div className={styles.container}>
      {cards.map((item: CardItemType, index) => {
        return (
          <div className={styles.card} key={new Date().getTime() + index}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }}></div>
            <div className={styles.divider}></div>
            <div className={styles.description}>{item.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default RoomStayBlogListCard;
