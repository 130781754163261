import { FC } from 'react';

import { useElementSize, useWindowSize } from 'usehooks-ts';
import RoomStayBlogCardPricing from '../roomstay-blog-card-pricing/roomstay-blog-card-pricing';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';
import styles from './roomstay-blog-hero-pricing.module.scss';

export interface HeroPricingProps {
    subTitle?: string;
    title?: string;
    subDescription?: string;
    description?: string;

    cardPricing?: {
        subTitle?: string;
        title?: string;
        description?: string;
        link?: string;
        successMessage?: string;
    };
}

const RoomStayBlogHeroPricing: FC<HeroPricingProps> = (props) => {
    const { title, subTitle, subDescription, description, cardPricing } = props;

    const [containerRef, { height }] = useElementSize();
    const [introRef, { height: introHeight }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    return (
        <div ref={containerRef} className={styles.container}>
            <ClippedBox
                configs={[
                    {
                        rad: Math.atan(405 / 1920),
                        position: POSITION.BottomRight,
                    },
                ]}
                className={styles.background}
                style={{
                    backgroundColor: '#F1ECFF',
                    maxHeight: `${windowWidth > 768 ? introHeight + 1000 : introHeight + 1100}px`,
                }}
            />

            <div className={styles.wrapperContent}>
                <div className={styles.content}>
                    <div className={styles.contentIntro}>
                        {' '}
                        <div className={styles.subTitle}>{subTitle}</div>
                        <div className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }}></div>
                        <div className={styles.subDescription}>{subDescription}</div>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }}></div>
                    </div>

                    <RoomStayBlogCardPricing
                        contentText={{
                            subTitle: cardPricing?.subTitle,
                            title: cardPricing?.title,
                            description: cardPricing?.description,
                        }}
                        contentForm={{
                            successMessage: cardPricing?.successMessage,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RoomStayBlogHeroPricing;
