import { BuilderConfig } from '@/types/builder.d';

import RoomstayBlogButton, { Props } from './roomstay-blog-button';

export const RoomstayBlogButtonBuilderConfig = {
    name: 'Button',
    inputs: [
        {
            name: 'text',
            type: 'text',
        },
        {
            name: 'link',
            type: 'url',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogButton,
    config: RoomstayBlogButtonBuilderConfig,
};

export default builderConfig;
