import { BuilderConfig } from '@/types/builder';

import RoomstayBlogClippedSection, { EBackgroundColor, EColor, EJoinerColor, RoomstayBlogClippedSectionProps } from './roomstay-blog-clipped-section';
import { withChildren } from '@builder.io/react';

export const RoomstayBlogClippedSectionBuilderConfig = {
    name: 'Clipped Section',
    inputs: [
        {
            name: 'color',
            type: 'string',
            enum: Object.values(EColor),
        },
        {
            name: 'backgroundColor',
            type: 'string',
            enum: Object.values(EBackgroundColor),
        },
        {
            name: 'joinerColor',
            type: 'string',
            enum: Object.values(EJoinerColor),
        },
        {
            name: 'container',
            type: 'boolean'
        },
        {
            name: 'noPaddingTop',
            type: 'boolean'
        }
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogClippedSectionProps> = {
    component: withChildren(RoomstayBlogClippedSection),
    config: RoomstayBlogClippedSectionBuilderConfig,
};

export default builderConfig;
