import { BuilderConfig } from '@/types/builder';

import RoomstayBlogTextCaption, { Props } from './roomstay-blog-text-caption';

export const RoomstayBlogTextCaptionBuilderConfig = {
    name: 'Text Caption',
    inputs: [
        {
            name: 'text',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogTextCaption,
    config: RoomstayBlogTextCaptionBuilderConfig,
};

export default builderConfig;
