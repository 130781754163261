import { BuilderConfig } from '@/types/builder';

import RoomstayBlogIntegrations, { RoomstayBlogIntegrationsProps } from './roomstay-blog-integrations';

export const RoomstayBlogIntegrationsBuilderConfig = {
    name: 'Integrations',
    inputs: [
        {
            name: 'cards',
            type: 'list',
            subFields: [
                {
                    name: 'logo',
                    type: 'file',
                },
                {
                    name: 'logoHeight',
                    type: 'number',
                },
                {
                    name: 'title',
                    type: 'text',
                },
                {
                    name: 'description',
                    type: 'text',
                },
                {
                    name: 'linkTo',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogIntegrationsProps> = {
    component: RoomstayBlogIntegrations,
    config: RoomstayBlogIntegrationsBuilderConfig,
};

export default builderConfig;
