import { BuilderConfig } from '@/types/builder';

import RoomstayBlogSemList, { RoomstayBlogSemListProps } from './roomstay-blog-sem-list';

export const RoomstayBlogQuoteBuilderConfig = {
    name: 'Sem List',
    inputs: [
        {
            name: 'cards',
            type: 'list',
            subFields: [
                {
                    name: 'image',
                    type: 'file',
                },
                {
                    name: 'title',
                    type: 'html',
                },
                {
                    name: 'description',
                    type: 'html',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogSemListProps> = {
    component: RoomstayBlogSemList,
    config: RoomstayBlogQuoteBuilderConfig,
};

export default builderConfig;
