import { FC } from 'react';

import styles from './roomstay-blog-text-synxis-booking.module.scss';

export interface Props {
    title: string;
    description: string;
}

const RoomStayBlogTextSynXisBooking: FC<Props> = (props) => {
    const { title, description } = props;

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
                <div className={styles.titleDivider}></div>
            </div>

            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    );
};

export default RoomStayBlogTextSynXisBooking;
