import classNames from 'classnames';
import { FC } from 'react';

import FadeUpOnVisible from '@/components/animations/FadeUpOnVisible';
import AnimationDelay from '@/models/AnimationDelay';
import { LazyLoadImage } from '../lazy-load-image';

import styles from './roomstay-blog-featured-card.module.scss';

export interface RoomstayBlogFeaturedCardProps {
    inverted?: boolean;
    description?: string;
    title?: string;
    graphic?: string;
    fontSize?: 'default' | 'medium';
    dividerColor?: string;
}

const RoomstayBlogFeaturedCard: FC<RoomstayBlogFeaturedCardProps> = (props) => {
    const classes = classNames('row feature-card', {
        '--right': props.inverted,
        '--left': !props.inverted,
    });

    const firstChildClasses = classNames('col-md-6', {
        'offset-md-1': props.inverted,
    });

    const secondChildClasses = classNames('col-md-5', {
        'offset-md-1': !props.inverted,
    });

    return (
        <div className={classes}>
            <div className={firstChildClasses}>
                <FadeUpOnVisible delay={AnimationDelay.A200}>
                    <LazyLoadImage imageUrl={props.graphic || ''} className={styles.mw100} />
                </FadeUpOnVisible>
            </div>
            <div className={secondChildClasses}>
                <FadeUpOnVisible>
                    <h2
                        className={classNames(`headline `, {
                            '--medium': props.fontSize === 'medium',
                            '--default': props.fontSize === 'default',
                        })}
                        dangerouslySetInnerHTML={{
                            __html: props.title || '',
                        }}
                    />
                    <div style={{ backgroundColor: props.dividerColor }} className="divider"></div>
                    <div
                        className="body-text"
                        dangerouslySetInnerHTML={{
                            __html: props.description || '',
                        }}
                    ></div>
                </FadeUpOnVisible>
            </div>
        </div>
    );
};

export default RoomstayBlogFeaturedCard;
