import Link from 'next/link';
import { FC } from 'react';

import Button from '@/components/button';
import LazyLoadImage from '@/components/lazy-load-image/lazy-load-image';
import styles from './article-card.module.scss';

interface ItemArticleCardProps {
    image?: string;
    category?: string;
    date?: number;
    title?: string;
    description?: string;
    url?: string;
}

const ArticleCard: FC<ItemArticleCardProps> = (props) => {
    const { image, title, description, url } = props;

    return (
        <Link href={url || ''} passHref>
            <a className={styles.root}>
                <div className={styles.zoomedImageContainer}>
                    {image ? <LazyLoadImage className={styles.zoomedImage} imageUrl={image} objectFit="cover" relativeContainer={false} /> : null}
                </div>

                <div className={styles.main}>
                    <div className={styles.content}>
                        <div className={styles.title} dangerouslySetInnerHTML={{ __html: title || '' }} />
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description || '' }} />
                        <div className={styles.btnLearnMore}>
                            <Button color="primary">Learn more</Button>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
};

export default ArticleCard;
