import { BuilderConfig } from '@/types/builder';

import RoomstayBlogImageGrid, { RoomstayBlogImageGridProps } from './roomstay-blog-image-grid';

export const RoomstayBlogImageGridBuilderConfig = {
    name: 'Image Grid',
    inputs: [
        {
            name: 'rows',
            type: 'list',
            subFields: [
                {
                    name: 'images',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        {
                            name: 'image',
                            type: 'file',
                        },
                        {
                            name: 'width',
                            type: 'string',
                        },
                        {
                            name: 'widthMobile',
                            type: 'string',
                        },
                        {
                            name: 'height',
                            type: 'string',
                        },
                        {
                            name: 'col',
                            type: 'number',
                        },
                        {
                            name: 'row',
                            type: 'number',
                        },
                    ],
                },
            ],
        },
        {
            name: 'rowGap',
            type: 'number',
        },
        {
            name: 'columnGap',
            type: 'number',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogImageGridProps> = {
    component: RoomstayBlogImageGrid,
    config: RoomstayBlogImageGridBuilderConfig,
};

export default builderConfig;
