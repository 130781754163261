import { LazyLoadImage } from '@/components/lazy-load-image';
import Link from 'next/link';
import { FC } from 'react';
import styles from './card.module.scss';

export interface CardIntegrationsProps {
    logo?: string;
    logoHeight?: number;
    title?: string;
    description?: string;
    linkTo?: string;
}

const CardContent: FC<CardIntegrationsProps> = ({ logo, logoHeight, title, description }) => (
    <div className={styles.card}>
        {logo && (
            <div className={styles.wrapLogo}>
                <div className={styles.box} style={{ height: logoHeight ? `${logoHeight}px` : '' }}>
                    <LazyLoadImage className={styles.logo} imageUrl={logo} objectPosition={'left'} />
                </div>
            </div>
        )}
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
    </div>
);

const CardIntegrations: FC<CardIntegrationsProps> = ({ linkTo, ...props }) => (
    <Link href={linkTo || ''} passHref>
        {linkTo ? (
            <a target="_blank" style={{ textDecoration: 'unset' }} onClick={(e) => !linkTo && e.preventDefault()}>
                <div className={styles.container}>
                    <CardContent {...props} />
                </div>
            </a>
        ) : (
            <div className={styles.container} onClick={(e) => !linkTo && e.preventDefault()}>
                <CardContent {...props} />
            </div>
        )}
    </Link>
);

export default CardIntegrations;
