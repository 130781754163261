import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

export enum EColor {
    White = 'White',
    Dark = 'Dark',
}

export enum EBackgroundColor {
    Light = 'Light',
    Dark = 'Dark',
}

export enum EJoinerColor {
    Navy = 'Navy',
    White = 'White',
}

export interface RoomstayBlogClippedSectionProps extends PropsWithChildren {
    color: EColor;
    backgroundColor: EBackgroundColor;
    joinerColor: EJoinerColor;
    container?: boolean;
    noPaddingTop?: boolean;
}

const RoomstayBlogClippedSection: FC<RoomstayBlogClippedSectionProps> = ({ children, noPaddingTop, container, color, backgroundColor, joinerColor }) => {
    const getColor = () => {
        switch (color) {
            case EColor.Dark:
                return '--dark';
            case EColor.White:
                return '--white';
        }
    };
    const getBackgroundColor = () => {
        switch (backgroundColor) {
            case EBackgroundColor.Light:
                return '--theme-light';
            case EBackgroundColor.Dark:
                return '--theme-dark';
        }
    };
    const getJoinerColor = () => {
        switch (joinerColor) {
            case EJoinerColor.Navy:
                return '--to-navy';
            case EJoinerColor.White:
                return '--to-white';
        }
    };

    let Container,
        props = {};
    if (container) {
        Container = 'div';
        props = { className: 'container' };
    } else {
        Container = React.Fragment;
    }
    return (
        <div
            className={classNames('centered-body', '--no-min-height', getColor(), getBackgroundColor(), {
                'pt-0': noPaddingTop,
            })}
        >
            <div className={classNames('centered-body--joiner', getJoinerColor())} />
            <Container {...props}>{children}</Container>
        </div>
    );
};

export default RoomstayBlogClippedSection;
