import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHomeIntroduction, { RoomstayBlogIntroductionProps, SPACING_CONTENT, STYLE_TITLE } from './roomstay-blog-home-introduction';

export const RoomstayBlogIntroductionBuilderConfig = {
    name: 'Home Introduction',
    inputs: [
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'styleTitle',
            type: 'string',
            enum: Object.values(STYLE_TITLE),
            defaultValue: Object.values(STYLE_TITLE.centered),
        },
        {
            name: 'divider',
            type: 'boolean',
        },
        {
            name: 'spacingContent',
            type: 'string',
            enum: Object.values(SPACING_CONTENT),
            defaultValue: Object.values(SPACING_CONTENT.largest),
        },
        {
            name: 'cards',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'title',
                    type: 'html',
                },

                { name: 'content', type: 'html' },
                {
                    name: 'hideIcon',
                    type: 'boolean',
                },
                {
                    name: 'background',
                    type: 'string',
                    enum: ['primary', 'secondary', 'cyan', 'navy', 'white', 'purple'],
                },
                {
                    name: 'iconFillColor',
                    type: 'color',
                },
                {
                    name: 'minHeight',
                    type: 'number',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogIntroductionProps> = {
    component: RoomstayBlogHomeIntroduction,
    config: RoomstayBlogIntroductionBuilderConfig,
};

export default builderConfig;
