import { BuilderConfig } from '@/types/builder';

import RoomstayBlogProductsHero, { RoomstayBlogProductsHeroProps } from './roomstay-blog-products-hero';

export const RoomstayBlogProductsHeroBuilderConfig = {
    name: 'Product Hero',
    inputs: [
        {
            name: 'title',
            type: 'text',
        },
        {
            name: 'description',
            type: 'text',
        },
        {
            name: 'buttons',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                { name: 'sectionId', type: 'string' },
            ],
        },
        {
            name: 'card',
            type: 'object',
            subFields: [
                {
                    name: 'title',
                    type: 'text',
                },
                {
                    name: 'description',
                    type: 'text',
                },
                {
                    name: 'graphic',
                    type: 'file',
                },
                {
                    name: 'inverted',
                    type: 'boolean',
                },
                {
                    name: 'ctaButton',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'href',
                            type: 'string',
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogProductsHeroProps> = {
    component: RoomstayBlogProductsHero,
    config: RoomstayBlogProductsHeroBuilderConfig,
};

export default builderConfig;
