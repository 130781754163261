import { FC, PropsWithChildren } from 'react';
import FadeUpOnVisible from '../animations/FadeUpOnVisible';

export interface RoomstayBlogFadeUpProps extends PropsWithChildren {
    autoPadding?: boolean;
}

const RoomstayBlogFadeUp: FC<RoomstayBlogFadeUpProps> = (props) => {
    const { autoPadding, children } = props;

    return (
        <div className={`container${autoPadding ? ' --padded' : ''}`}>
            <FadeUpOnVisible>{children}</FadeUpOnVisible>
        </div>
    );
};

export default RoomstayBlogFadeUp;
