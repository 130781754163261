import { BuilderConfig } from '@/types/builder';

import RoomstayBlogFooterLayoutForm, { RoomstayFooterLayoutFormProps } from './roomstay-blog-footer-layout-form';

export const RoomstayBlogFooterLayoutFormBuilderConfig = {
    name: 'Footer Layout Forms',
    inputs: [
        {
            name: 'buttonText',
            type: 'string',
        },
        {
            name: 'titleForm',
            type: 'string',
        },
        {
            name: 'showTextFooter',
            type: 'boolean',
        },
        {
            name: 'headlineFooter',
            type: 'html',
        },
        {
            name: 'subHeadlineFooter',
            type: 'html',
        },
        {
            name: 'buttonNameFooter',
            type: 'string',
        },
        {
            name: 'buttonLinkFooter',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayFooterLayoutFormProps> = {
    component: RoomstayBlogFooterLayoutForm,
    config: RoomstayBlogFooterLayoutFormBuilderConfig,
};

export default builderConfig;
