import classNames from 'classnames';
import * as React from 'react';

interface CardProps {
    title: string;
    description: string;
    icon?: React.ReactElement;
    color?: 'primary' | 'secondary' | 'cyan' | 'navy' | 'white' | 'purple';
    minheight?: string;
    children?: React.ReactElement;
    showIcon?: boolean;
}

export default function Card(props: CardProps) {
    const theme = props?.color === 'purple';

    const classes = classNames('card', {
        [props.color ? `--${props.color}` : '--primary']: true,
        [props.minheight ? `--mh${props.minheight}` : '']: true,
        '--theme': theme,
    });

    const setIcon = props.showIcon;

    return (
        <div className={classes}>
            <div className="content">
                <div
                    className="headline --smaller --with-spacer"
                    dangerouslySetInnerHTML={{
                        __html: props.title,
                    }}
                />

                <div
                    className="body-text"
                    dangerouslySetInnerHTML={{
                        __html: props.description,
                    }}
                />
            </div>
            <div className={'cardIcon'}>{setIcon && props.icon}</div>
        </div>
    );
}
