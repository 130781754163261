import classNames from 'classnames';
import { FC } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import LazyLoadImage from '../lazy-load-image/lazy-load-image';
import ClippedBox, { POSITION } from '../roomstay-blog-image-block/clipped-box';

import styles from './roomstay-members.module.scss';

type RoomstayMemberCardType = {
    avatar?: string;
    name: string;
    jobTitle: string;
    description: string;
};

export interface Props {
    title: string;
    subTitle: string;
    description: string;
    members: RoomstayMemberCardType[];
}

const RoomstayMembers: FC<Props> = (props) => {
    const { members = [], title, subTitle, description } = props;
    const [containerRef, { height }] = useElementSize();
    const { width: windowWidth } = useWindowSize();

    let clippedRadian = Math.atan(1438 / 1920);
    let clippedMaxHeight = `${height * 0.38}px`;

    if (windowWidth < 1300) {
        clippedRadian = Math.atan(0.3);
        clippedMaxHeight = `${height * 0.33}px`;
    }

    if (windowWidth < 998) {
        clippedMaxHeight = `${height * 0.24}px`;
    }

    if (windowWidth < 768) {
        clippedRadian = Math.atan(0.3);
        clippedMaxHeight = `${height * 0.175}px`;
    }

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.intro}>
                <div className={styles.heading}>{title}</div>
                <div className={styles.divider}></div>
                <div className={styles.title}>{subTitle}</div>
                <div className={styles.description}>{description}</div>
            </div>

            <div className={styles.memberContainer}>
                {members.map((item: RoomstayMemberCardType, index) => {
                    return (
                        <div className={styles.member} key={new Date().getTime() + index}>
                            <div className={styles.avatar}>{item.avatar ? <LazyLoadImage imageUrl={item.avatar} objectFit="cover" /> : null}</div>
                            <div className={styles.infoBlock}>
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.jobTitle}>{item.jobTitle}</div>
                                <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className={styles.background}></div>
            <ClippedBox
                configs={[
                    {
                        rad: clippedRadian,
                        position: POSITION.BottomRight,
                    },
                ]}
                className={classNames(styles.background, styles.backgroundClipped)}
                style={{
                    maxHeight: clippedMaxHeight,
                }}
            />
        </div>
    );
};

export default RoomstayMembers;
