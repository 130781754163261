import { BuilderConfig } from '@/types/builder';

import RoomStayBlogArticleCard, { RoomStayBlogArticleCardProps } from './roomstay-blog-article-card';

export const RoomstayBlogArticleCardBuilderConfig = {
    name: 'RoomStay Article Card',
    inputs: [
        {
            name: 'articleCard',
            type: 'list',
            subFields: [
                {
                    name: 'image',
                    type: 'file',
                },
                {
                    name: 'title',
                    type: 'html',
                },
                {
                    name: 'description',
                    type: 'html',
                },
                {
                    name: 'url',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<RoomStayBlogArticleCardProps> = {
    component: RoomStayBlogArticleCard,
    config: RoomstayBlogArticleCardBuilderConfig,
};

export default builderConfig;
