import { BuilderConfig } from '@/types/builder';

import { withChildren } from '@builder.io/react';
import RoomstayBlogProductTheme, { RoomstayBlogProductThemeProps } from './roomstay-blog-product-theme';

export const RoomstayBlogProductThemeBuilderConfig = {
    name: 'Product Theme',
    inputs: [
        {
            name: 'autoPadding',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'backgroundColor',
            type: 'color',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogProductThemeProps> = {
    component: withChildren(RoomstayBlogProductTheme),
    config: RoomstayBlogProductThemeBuilderConfig,
};

export default builderConfig;
