import { FC } from 'react';

import ArticleCard from './article-card/article-card';
import styles from './roomstay-blog-article-card.module.scss';

export interface ContentCard {
    image?: string;
    category?: string;
    date?: number | undefined;
    title?: string;
    description?: string;
    url?: string;
}

export interface RoomStayBlogArticleCardProps {
    articleCard?: ContentCard[];
}

const RoomStayBlogArticleCard: FC<RoomStayBlogArticleCardProps> = (props) => {
    const { articleCard = [] } = props;

    return (
        <div className={styles.container}>
            <div className={styles.header}>Explore more RoomStay Products</div>
            <div className={styles.wrapper}>
                {articleCard.map((item, index) => {
                    return (
                        <ArticleCard key={index} image={item.image} category={item.category} date={item.date} title={item.title} description={item.description} url={item.url} />
                    );
                })}
            </div>
        </div>
    );
};

export default RoomStayBlogArticleCard;
