import { FC } from 'react';

import { LazyLoadImage } from '../lazy-load-image';

import styles from './roomstay-blog-our-promise.module.scss';

type OurPromiseType = {
    icon?: string;
    text: string;
};
export interface Props {
    title: string;
    items: OurPromiseType[];
}

const RoomStayOurPromise: FC<Props> = (props) => {
    const { items = [], title } = props;

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>{title}</div>
                <div className={styles.content}>
                    {items.map((item: OurPromiseType, index) => {
                        return (
                            <div className={styles.item} key={new Date().getTime() + index}>
                                <div className={styles.icon}>
                                    {item.icon && <LazyLoadImage imageUrl={item.icon} className="w-100" alt="avatar" ratio="1/1" loadingPlaceholder={false} />}
                                </div>
                                <div className={styles.text}>{item.text}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RoomStayOurPromise;
