import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHomeHeadline, { RoomstayBlogHeadlineProps } from './roomstay-blog-home-headline';

export const RoomstayBlogHomeHeadlineBuilderConfig = {
    name: 'Home Headline',
    inputs: [
        {
            name: 'text',
            type: 'html',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHeadlineProps> = {
    component: RoomstayBlogHomeHeadline,
    config: RoomstayBlogHomeHeadlineBuilderConfig,
};

export default builderConfig;
