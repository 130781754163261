export type ImageResizeParameters = {
    width?: number;
    height?: number;
    quality?: number;
    format?: 'webp';
};

export const getResizedBuilderImage = (source: string | undefined, parameters?: ImageResizeParameters) => {
    if (source && source.startsWith('https://cdn.builder.io/api/v1/image/')) {
        let queryArray: string[] = [];

        if (parameters) {
            parameters.quality = parameters.quality ?? 85;
            parameters.format = 'webp';

            const parameterKeys = Object.keys(parameters);
            parameterKeys.forEach((paramKey) => {
                const paramValue = parameters[paramKey as keyof ImageResizeParameters];
                if (paramValue) {
                    queryArray.push(`${paramKey}=${paramValue}`);
                }
            });
        }

        return `${source}?${queryArray.join('&')}`;
    }

    return source;
};

export const getPlaceholderBuilderImage = (source: string | undefined) => {
    return getResizedBuilderImage(source, { width: 1, height: 1 });
};
