import { BuilderConfig } from '@/types/builder.d';

import RoomStayBlogCardPricing, { BlogCardPricing } from './roomstay-blog-card-pricing';

export const RoomStayBlogCardPricingBuilderConfig = {
    name: 'Pricing Card',
    inputs: [
        {
            name: 'contentText',
            type: 'object',
            subFields: [
                {
                    name: 'subTitle',
                    type: 'html',
                    defaultValue: '',
                },
                {
                    name: 'title',
                    type: 'html',
                    defaultValue: '',
                },
                {
                    name: 'description',
                    type: 'html',
                    defaultValue: '',
                },
            ],
        },
        {
            name: 'contentForm',
            type: 'object',
            subFields: [
                {
                    name: 'backgroundColor',
                    type: 'color',
                    defaultValue: '',
                },
                {
                    name: 'successMessage',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BlogCardPricing> = {
    component: RoomStayBlogCardPricing,
    config: RoomStayBlogCardPricingBuilderConfig,
};

export default builderConfig;
