import { BuilderConfig } from '@/types/builder';

import RoomStayBlogTextSynXisBooking, { Props } from './roomstay-blog-text-synxis-booking';

export const RoomStayBlogTextSynXisBookingBuilderConfig = {
    name: 'Text SynXis Booking',
    inputs: [
        {
            name: 'title',
            type: 'html',
            defaultValue: '',
        },

        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomStayBlogTextSynXisBooking,
    config: RoomStayBlogTextSynXisBookingBuilderConfig,
};

export default builderConfig;
