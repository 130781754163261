import cx from 'classnames';
import { CSSProperties, FC, useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import { RawLazyLoadImage } from '../lazy-load-image';

import styles from './roomstay-blog-image-grid.module.scss';

interface ImageItemType {
    image: string;
    width?: CSSProperties['width'];
    widthMobile?: CSSProperties['width'];
    height?: CSSProperties['height'];
}

export interface RoomstayBlogImageGridProps {
    rows: {
        images: ImageItemType[];
    }[];
    rowGap?: number;
    columnGap?: number;
}

const getVal = (val: CSSProperties['width'] | CSSProperties['height']) => {
    if (typeof val === 'string') {
        const number = Number.parseFloat(val);
        if (val) {
            return number;
        }
    }

    return val;
};

const RoomstayBlogImageGrid: FC<RoomstayBlogImageGridProps> = (props) => {
    const { rows = [] } = props;
    const { width: windowWidth } = useWindowSize();
    const [containerRef, size] = useElementSize();
    const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [isGroup, setIsGroup] = useState<boolean>(true);
    const deferredContainerWidth = useDeferredValue(size.width);

    const checkRowWidth = useCallback(() => {
        let maxWidth = 0;
        rowRefs.current?.forEach((row) => {
            if (row && row.offsetWidth > maxWidth) {
                maxWidth = row.offsetWidth;
            }
        });

        setIsGroup(maxWidth > deferredContainerWidth);
    }, [deferredContainerWidth]);

    useEffect(() => {
        checkRowWidth();
    }, [checkRowWidth]);

    useEffect(() => {
        checkRowWidth();
    }, []);

    const renderImages = (images: ImageItemType[]) => {
        return images?.map(({ image, width, widthMobile, height: itemHeight = 64 }) => {
            if (!image) return null;

            let maxHeight = getVal(itemHeight);
            if (windowWidth < 768 && typeof maxHeight === 'number') {
                maxHeight = (maxHeight / 5) * 3;
            }

            return (
                <div key={image} className={styles.item}>
                    <RawLazyLoadImage className={styles.image} imageUrl={image} style={{ maxWidth: windowWidth < 768 ? getVal(widthMobile) : getVal(width), maxHeight }} priority />
                </div>
            );
        });
    };

    const renderRowGap = () => {
        if (props.rowGap) {
            const rowGap = `${props.rowGap}px`;
            return { '--row-gap': rowGap } as React.CSSProperties;
        }
        return {};
    };

    const renderColumnGap = () => {
        if (props.columnGap) {
            const columnGap = `${props.columnGap}px`;
            return { '--column-gap': columnGap } as React.CSSProperties;
        }
        return {};
    };

    return (
        <div ref={containerRef} style={{ ...renderRowGap(), ...renderColumnGap() }} className={cx(styles.container)}>
            {rows.map((row, index) => {
                return (
                    <div key={`${index}_${row.images?.length}`} ref={(ref: any) => (rowRefs.current[index] = ref)} className={cx(styles.row, { [styles.hide]: isGroup })}>
                        {renderImages(row.images)}
                    </div>
                );
            })}
            <div className={cx(styles.row, styles.wrap, { [styles.hide]: !isGroup })}>
                {rows.map((row) => {
                    return renderImages(row.images);
                })}
            </div>
        </div>
    );
};

export default RoomstayBlogImageGrid;
