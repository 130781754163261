import { useState } from 'react';

import dynamic from 'next/dynamic';
import { LazyLoadImageNaturalSize } from './lazy-load-image';
import LazyLoadImage from './lazy-load-image/lazy-load-image';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface CentralYoutubeBlockProps {
    url: string;
    placeholderImageSrc: string;
}

export default function CentralYoutubeBlock(props: CentralYoutubeBlockProps) {
    const { url } = props;
    const [showVideo, setShowVideo] = useState(false);

    return (
        <div className="central-video-block">
            <div className="central-video-block--content">
                <div className="central-video-block--video" style={{ display: showVideo ? 'block' : 'none' }}>
                    <ReactPlayer url={props.url} playing={showVideo ? true : false} />
                </div>

                {!showVideo && (
                    <div className="central-video-block--placeholder" onClick={() => setShowVideo(true)}>
                        <LazyLoadImageNaturalSize unoptimized className="central-video-block--placeholder-image" imageUrl={props.placeholderImageSrc} />
                        <LazyLoadImage
                            className="central-video-block--placeholder-play"
                            imageUrl="/assets/img/video-play.png"
                            alt="Play the video"
                            relativeContainer={false}
                            unoptimized
                        />
                    </div>
                )}

                <div className="central-video-block--colour-blocks">
                    <div className="central-video-block--colour-block first" />
                    <div className="central-video-block--colour-block second"></div>
                    <div className="central-video-block--colour-block third"></div>
                    <div className="central-video-block--colour-block fourth"></div>
                    <div className="central-video-block--colour-block fifth"></div>
                    <div className="central-video-block--colour-block sixth"></div>
                </div>
            </div>
        </div>
    );
}
