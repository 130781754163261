import { BuilderContent } from '@builder.io/react';

import { Category } from '@/types/category';

import BaseModel from './BaseModel';

class CategoryModel extends BaseModel {
    constructor() {
        super({ modelName: 'category' });
    }

    fromBuilderContent = (object: any): Category => {
        if (!object) {
            return {
                id: '',
                name: '',
                longName: null,

                description: '',
                image: '',
                lastUpdated: '',
            };
        }

        let name;
        let category = object;

        if (object.data === undefined) {
            name = object.name ?? '';
        } else {
            category = object.data;
            name = object.name ?? object.data.name;
        }

        return {
            id: object.id,
            name,
            longName: category.longName ?? null,
            description: category.description || '',
            image: category.image || '',
            lastUpdated: object.lastUpdated || '',

            // categoryUrl: categorySlug.replace(/\/{2,}/g, '/'),
            // categorySlug,
        };
    };

    fromListBuilderContent = (objects: BuilderContent<Category>[]): Category[] => {
        return objects.map(this.fromBuilderContent);
    };
}

export default new CategoryModel();
