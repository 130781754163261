import HomepageDemoBlock, { InfoBlockProps } from '@/components/HomepageDemoBlock';
import { NOREPLY_EMAIL } from '@/shared/env';
import { FC } from 'react';

export interface RoomstayBlogHomeHeroProps {
    leftContentBlock?: {
        title?: string;
        description?: string;
        ctaButton?: {
            text?: string;
            href?: string;
        };
    };
    rightContentBlock?: {
        imageMain?: string;
        imageTop?: string;
        textCenter?: InfoBlockProps;
        textBottom?: InfoBlockProps;
    };
}

const RoomstayBlogHomeHero: FC<RoomstayBlogHomeHeroProps> = ({ leftContentBlock, rightContentBlock }) => {
    return (
        <div className="centered-body --no-min-height">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-5">
                        <div className="u-marg-bottom">
                            <h1
                                className="headline"
                                dangerouslySetInnerHTML={{
                                    __html: leftContentBlock?.title || '',
                                }}
                            />
                        </div>
                        <div className="u-marg-bottom--large">
                            <div
                                className="body-text --large"
                                dangerouslySetInnerHTML={{
                                    __html: leftContentBlock?.description || '',
                                }}
                            />
                        </div>

                        {leftContentBlock?.ctaButton?.text && (
                            <a href={leftContentBlock?.ctaButton?.href || `mailto:${NOREPLY_EMAIL}`} className="btn --primary">
                                {leftContentBlock?.ctaButton?.text}
                            </a>
                        )}
                    </div>
                    <div className="col-xl-7">
                        <HomepageDemoBlock
                            imageMain={rightContentBlock?.imageMain}
                            imageTop={rightContentBlock?.imageTop}
                            textCenter={rightContentBlock?.textCenter}
                            textBottom={rightContentBlock?.textBottom}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomstayBlogHomeHero;
