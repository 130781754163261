import { BuilderConfig } from '@/types/builder';

import RoomstayBlogHomeImageBlock, { RoomstayBlogHomeImageBlockProps } from './roomstay-blog-home-image-block';

export const RoomstayBlogHomeImageBlockBuilderConfig = {
    name: 'Home Image Block',
    inputs: [
        {
            name: 'image',
            type: 'file',
        },
        {
            name: 'title',
            type: 'html',
        },
        {
            name: 'description',
            type: 'html',
        },
    ],
};

const builderConfig: BuilderConfig<RoomstayBlogHomeImageBlockProps> = {
    component: RoomstayBlogHomeImageBlock,
    config: RoomstayBlogHomeImageBlockBuilderConfig,
};

export default builderConfig;
