import * as React from 'react';

interface InfoBlockProps {
    text: string;
    color?: 'purple' | 'pink';
}

export default function InfoBlock(props: InfoBlockProps) {
    return <div className={'info-block ' + `--${props.color ?? 'purple'}`}>{props.text}</div>;
}
