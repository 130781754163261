import { BUILDER_MODEL, BuilderConfig } from '@/types/builder.d';

import RoomstayBlogHighlightArticle, { Props } from './roomstay-blog-highlight-article';

export const RoomstayBlogHighlightArticleConfig = {
    name: 'Highlight Article',
    inputs: [
        {
            name: 'article',
            type: 'reference',
            model: BUILDER_MODEL.ARTICLE,
        },
        {
            name: 'topColor',
            type: 'color',
        },
        {
            name: 'bottomColor',
            type: 'color',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomstayBlogHighlightArticle,
    config: RoomstayBlogHighlightArticleConfig,
};

export default builderConfig;
