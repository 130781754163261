import { FC } from 'react';

import styles from './roomstay-stats.module.scss';

type StatsType = {
  title: string;
  description: string;
};

export interface Props {
  items: StatsType[];
}

const RoomstayStats: FC<Props> = (props) => {
  const { items = [] } = props;

  return (
    <div className={styles.container}>
      {items.map((item: StatsType, index) => {
        return (
          <div className={styles.content} key={new Date().getTime() + index}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.description}>{item.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default RoomstayStats;
