export const calculateRevParAverageDaily = (dailyRates: number, occupancyRates: number) => {
  if (occupancyRates === 0) {
    return 0;
  }
  const occupancyPercentage = occupancyRates / 100; // convert occupancy rate to a decimal

  const revPAR = dailyRates * occupancyPercentage;
  return revPAR.toFixed(2); // calculate RevPAR
};


export const calculateRevParTotalRoom = (totalRoomRevenues: number, totalNumberOfRooms: number, periods: number) => {
  if (totalNumberOfRooms === 0) {
    return 0;
  }

  const adr = (totalRoomRevenues / totalNumberOfRooms);

  const revPar = periods * adr;
  return revPar.toFixed(2);// calculate RevPAR
};
