import { FC } from 'react';

import LazyLoadImage from '@/components/lazy-load-image/lazy-load-image';
import styles from './hotel-brand-item.module.scss';

export interface HotelBrandItemProps {
    logo: string;
    name: string;
    website: string;
}

const HotelBrandItem: FC<HotelBrandItemProps> = (props) => {
    const { logo, name, website } = props;

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <LazyLoadImage imageUrl={logo} objectPosition="left" />
            </div>
            <div className={styles.info}>
                <div className={styles.name}>{name}</div>
                <div className={styles.website}>{website}</div>
            </div>
        </div>
    );
};

export default HotelBrandItem;
