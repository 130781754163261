import { BuilderConfig } from '@/types/builder';

import RoomStayBlogTexIntroUser, { Props } from './roomstay-blog-text-intro-user';

export const RoomStayBlogTextIntroUserBuilderConfig = {
    name: 'Text intro user',
    inputs: [
        {
            name: 'title',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'subTitle',
            type: 'richText',
            defaultValue: '',
        },

        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: RoomStayBlogTexIntroUser,
    config: RoomStayBlogTextIntroUserBuilderConfig,
};

export default builderConfig;
